import React from "react"
import { Container} from 'react-bootstrap';
import { GoArrowRight } from "react-icons/go";
import Header from "../../shared/header/Header";
import Footer from "../../shared/footer/Footer";

function PageNotFound(props) {

return (
    <div className="pagenotfound planeHeader">
        <Header/>

        <section className="section-padd page404">
            <Container className="section-padd-top">
                <h2 className="text-center">
                    404 - PAGE DOES NOT EXIST
                </h2>
                <p className="text-center mt-4">This page either doesn’t exist anymore, has been moved or is temporarily unavailable. Check your spelling, or go back to home or the previous page.</p>
                <div className="text-center mt-5"><a href="/" className="btn btn-primary d-inline-block rounded-pill px-5 py-3">Back To Homepage <GoArrowRight/></a></div>
            </Container>
        </section>

        <Footer/>
    </div>
)
}

export default PageNotFound