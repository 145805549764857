import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { IoCloseOutline, IoSearchOutline } from 'react-icons/io5';
import { LiaSortSolid } from 'react-icons/lia';
import { PiFilesLight } from 'react-icons/pi';
import Header from '../../shared/header/Header';
import Footer from '../../shared/footer/Footer';
import { GlossaryApiService } from '../../services/apiService';
import { Link } from 'react-router-dom';

function Glossary() {
  const searchBoxRef = React.useRef(null);

  const [allGlossaries, setAllGlossaries] = React.useState([]);
  const [currentGlossaries, setCurrentGlossaries] = React.useState([]);

  React.useEffect(() => {
    GlossaryApiService.getGlossaries()
      .then((response) => {
        if (response.data.success === true) {
          setAllGlossaries(response.data.data);
          setCurrentGlossaries(response.data.data);
        }
      })
      .catch((error) => {
        console.log('Error', error);
      });
  }, []);

  return (
    <div className='glossary-container ll-logo-display'>
      <Header />

      <div className='inner-hero'>
        <Container>
          <div className='about-hero-txt'>
            <Row>
              <Col lg={10} className='wow fadeInUp mt-5 mt-lg-0'>
                <h1 className=''>Corporate Sustainability Glossary</h1>
                <p className='mt-4'>
                  All the jargon, terms and abbreviations you ever need to know
                  about carbon and ESG management.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <section className='section-padd-top opportunity-section'>
        <Container>
          <Row className='section-padd-bottom mt-lg-0'>
            <Col lg={3}>
              <div className='oppor-left me-4'>
                <div className='glossary-search'>
                  <Form>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h6 className='mb-0'>Search</h6>
                      <span
                        className='clearsearch'
                        onClick={() => {
                          searchBoxRef.current.value = '';
                          setCurrentGlossaries(allGlossaries);
                        }}
                      >
                        Clear <IoCloseOutline />
                      </span>
                    </div>
                    <Form.Group
                      className='mt-3 position-relative'
                      controlId='searchType'
                    >
                      <Form.Control
                        type='text'
                        ref={searchBoxRef}
                        onChange={(e) => {
                          let searchText = e.target.value.trim();
                          if (searchText === '') {
                            setCurrentGlossaries(allGlossaries);
                          } else {
                            let filteredGlossaries = allGlossaries.filter(
                              (glossary) =>
                                glossary.title
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                            );
                            setCurrentGlossaries(filteredGlossaries);
                          }
                        }}
                        placeholder='Type to search...'
                      />
                      <div className='searchicon'>
                        <IoSearchOutline />
                      </div>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </Col>
            <Col lg={9} className='mt-5 mt-lg-0'>
              <div className='glossary-sorting'>
                <div className='noofglossary'>
                  <span className='active-glo'>{currentGlossaries.length}</span>{' '}
                  of <span className='total-glo'>{allGlossaries.length}</span>{' '}
                  shown
                </div>
                <Button
                  variant=''
                  className='nameSOrt'
                  onClick={() => {
                    let sortedGlossaries = currentGlossaries.reverse();
                    setCurrentGlossaries([...sortedGlossaries]);
                  }}
                >
                  Name <LiaSortSolid />
                </Button>
              </div>

              {currentGlossaries.map((glossary, index) => (
                <div className='glossary-box wow fadeInUp' key={index}>
                  <Row className='mt-2'>
                    <Col sm={12} className='align-self-center'>
                      <h4 className='pb-2'>{glossary.title}</h4>
                      <div className='text-oppor'>
                        <p
                          dangerouslySetInnerHTML={{ __html: glossary.content }}
                        />
                        {glossary.relatedBlogId !== null && (
                          <div className='read-glossari'>
                            <Link
                              to={`/article/${glossary.relatedBlog.slug}`}
                              className='btn-link'
                            >
                              <PiFilesLight /> {glossary.relatedBlog.title}
                            </Link>
                          </div>
                        )}

                        {/* {glossary.sourceName && (
                          <div className='sourcetxt mt-2'>
                            <a
                              href={glossary.sourceLink || ''}
                              target='_blank'
                              rel='noreferrer'
                            >
                              Source: {glossary.sourceName}
                            </a>
                          </div>
                        )} */}
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}

              {/* <div className='glossary-box wow fadeInUp'>
                                <Row className='mt-2'>
                                    <Col sm={12} className='align-self-center'>
                                        <h4 className='pb-2'>Zero carbon</h4>
                                        <div className='text-oppor'>
                                            <p>Zero carbon means a product or service produces no carbon emissions. For example, renewables like wind and solar are referred to as zero carbon, as they do not emit carbon when producing electricity.</p>

                                            <p>Where net-zero refers to cancelling or balancing any carbon a company produces, zero carbon refers to a product or service emitting no CO2e. Further, net-zero carbon emissions mean that an activity releases net-zero carbon emissions into the atmosphere.</p>

                                            <div className='read-glossari'><a href='#' className='btn-link'><PiFilesLight /> What is the difference between carbon-neutral, net-zero and climate positive?</a></div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='glossary-box wow fadeInUp'>
                                <Row className='mt-2'>
                                    <Col sm={12} className='align-self-center'>
                                        <h4 className='pb-2'>Weather</h4>
                                        <div className='text-oppor'>
                                            <p>Weather is the state of the atmosphere at a particular place during a specific time, including pressure, temperature, wind, humidity, precipitation, and cloud cover. Weather is different from climate, which is all weather conditions for a particular location averaged over about 30 years.</p>
                                            <div className='read-glossari'><a href='#' className='btn btn-outline-primary px-4'>Learn More</a></div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='glossary-box wow fadeInUp'>
                                <Row className='mt-2'>
                                    <Col sm={12} className='align-self-center'>
                                        <h4 className='pb-2'>Waste generated in operations emissions (Scope 3 Category 5)</h4>
                                        <div className='text-oppor'>
                                            <p>Waste generated in operations emissions, classified under Scope 3 Category 5 in the GHG Protocol, represent the <b>greenhouse gas (GHG) emissions associated with the waste generated by a company's operations that are disposed of, treated, or recycled off-site.</b> These emissions are considered indirect emissions since they do not originate from sources owned or directly controlled by the reporting company, contrasting with Scope 1 direct emissions and Scope 2 <b>indirect emissions</b> from purchased energy.</p>

                                            <div className='read-glossari'><a href='#' className='btn btn-outline-primary px-4'>Learn More</a></div>
                                            <div className='sourcetxt mt-2'><a href='#'>Source: GHG Protocol</a></div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='glossary-box wow fadeInUp'>
                                <Row className='mt-2'>
                                    <Col sm={12} className='align-self-center'>
                                        <h4 className='pb-2'>Voluntary Emission Reductions (VER)</h4>
                                        <div className='text-oppor'>
                                            <p>Voluntary Emission Reductions (VER) are emission reductions made voluntarily and not mandated by any regulation or legislation. They usually originate from the will of an <a href='/'>organisation to take proactive</a> climate action.</p>

                                            <p>The voluntary market functions outside of the compliance market. Businesses, organisations, and individuals that wish to offset with no regulatory <a href='/'>obligation</a> can use Voluntary Emission Reductions. The carbon credits generated under the VER cannot be used in meeting <a href='/'>governmental compliance</a> measures as stated by the Kyoto Protocol.</p>

                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='glossary-box wow fadeInUp'>
                                <Row className='mt-2'>
                                    <Col sm={12} className='align-self-center'>
                                        <h4 className='pb-2'>Beyond value chain mitigation</h4>
                                        <div className='text-oppor'>
                                            <p>Beyond value chain mitigation (BVCM) are measures taken to avoid (prevent), reduce or eliminate <a href=''>greenhouse</a> gas emissions outside of their value chain. Both compensation and neutralisation can be considered BVCM, and should always come as an addition to decarbonisation, rather than its substitute.</p>

                                            <div className='read-glossari'><a href='#' className='btn-link'><PiFilesLight /> Net-zero journey, explained</a></div>

                                            <div className='sourcetxt mt-2'><a href='#'>Source: GHG Protocol</a></div>

                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='glossary-box wow fadeInUp'>
                                <Row className='mt-2'>
                                    <Col sm={12} className='align-self-center'>
                                        <h4 className='pb-2'>Atmosphere / Air</h4>
                                        <div className='text-oppor'>
                                            <p>The atmosphere is the layer of gases that envelopes a planet. Surprisingly, oxygen isn't the most abundant gas in our planet's atmosphere. The atmosphere of Earth is composed of nitrogen (78.1%), oxygen (20.9%), <a href='/'>argon (0.93%)</a>, <a href='/'>carbon dioxide (0.04%)</a>, and trace gases (such as neon, helium, methane, and krypton).</p>

                                            <div className='sourcetxt mt-2'><a href='#'>Source: GHG Protocol</a></div>

                                        </div>
                                    </Col>
                                </Row>
                            </div> */}
            </Col>
          </Row>
        </Container>
      </section>

      <section className='section-padd cta-section'>
        <Container>
          <div className='text-center cta-text wow fadeInUp'>
            <h2 className='mb-4 mb-lg-5'>
              Begin your decarbonization journey today
            </h2>
            <p>
              Our sustainability experts will advise you on an effective
              strategy for your business.
            </p>
            <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>
              Book A Demo
            </a>
          </div>
        </Container>
      </section>

      <Footer />
    </div>
  );
}

export default Glossary;
