import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import Header from '../../shared/header/Header';
import Footer from '../../shared/footer/Footer';
import { GoArrowRight } from 'react-icons/go';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import './Home.scss';
import {
  BrandLogoApiService,
  BlogApiService,
  SubCategoryApiService
} from '../../services/apiService';
import { COVER_IMAGE_BASE_URL, LOGOS_BASE_URL } from '../../services/constants';

function Home() {
  const [brandLogos, setBrandLogos] = useState([]);
  const [resoucesBlog, setResouces] = useState([]);
  const [casestudy, setCaseStudy] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const getBrandLogos = () => {
    BrandLogoApiService.getBrandLogos()
      .then((res) => {
        if (res.data) {
          setBrandLogos(
            res.data.filter((item) => {
              return item?.id !== 19;
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getResourcesBlog = async () => {
    BlogApiService.getResourceBlog()
      .then(async (res) => {
        if (res.data) {
          setResouces(
            await res.data.blog.filter((item) => {
              return item?.live;
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSubCategories = () => {
    SubCategoryApiService.getSubCategories()
      .then((response) => {
        if (response.data.success === true) {
          setSubCategories(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getCaseStudyBlog = async () => {
    BlogApiService.getCaseStudyBlog()
      .then(async (res) => {
        if (res.data) {
          setCaseStudy(
            await res.data.blog.filter((item) => {
              return item?.live;
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSubCategories();
    getCaseStudyBlog();
    getResourcesBlog();
    getBrandLogos();
  }, []);

  return (
    <div className='home-container ll-logo-display'>
      <Header />
      <div className='bc-home'>
        <Container>
          <section className='hero-section'>
            <Row>
              <Col lg={5} className='wow fadeInRight order-lg-last'>
                <img
                  src={'/assets/img/home/home-banner.webp'}
                  alt='Banner'
                  className='img-fluid'
                />
              </Col>
              <Col
                lg={7}
                className='wow fadeInUp align-self-center mt-4 mt-lg-0'
              >
                <h1>
                  Intelligent Sustainability Management with AI and Automation.
                </h1>
                <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>
                  Book A Demo
                </a>
              </Col>
            </Row>
          </section>

          <section className='section-padd-bottom journey-section'>
            <Row>
              <Col xs={6} md={4} lg={2} className='mt-4 mt-lg-0 wow fadeInUp'>
                <div className='journey-box'>
                  <div className='journey-front'>
                    <h6>Collect Data</h6>
                    <img
                      src={'/assets/img/home/collect-data.svg'}
                      alt='Collect Data'
                    />
                  </div>
                  <div className='servicesHover'>
                    <h6>Collect Data</h6>
                    <p>Easily gather and organize your emissions data.</p>
                    <a href='/collect-data' className='btn btn-link'>
                      Learn More <GoArrowRight />
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={4} lg={2} className='mt-4 mt-lg-0 wow fadeInDown'>
                <div className='journey-box'>
                  <div className='journey-front'>
                    <h6>Analyze Data</h6>
                    <img
                      src={'/assets/img/home/analyze-data.svg'}
                      alt='Analyze Data'
                    />
                  </div>
                  <div className='servicesHover'>
                    <h6>Analyze Data</h6>
                    <p>Get a detailed view of your carbon footprint.</p>
                    <a href='/analyze-data' className='btn btn-link'>
                      Learn More <GoArrowRight />
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={4} lg={2} className='mt-4 mt-lg-0 wow fadeInUp'>
                <div className='journey-box'>
                  <div className='journey-front'>
                    <h6>Report Data</h6>
                    <img
                      src={'/assets/img/home/report-data.svg'}
                      alt='report Data'
                    />
                  </div>
                  <div className='servicesHover'>
                    <h6>Report Data</h6>
                    <p>Quickly create ESG reports using AI and automation.</p>
                    <a href='/report-data' className='btn btn-link'>
                      Learn More <GoArrowRight />
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={4} lg={2} className='mt-4 mt-lg-0 wow fadeInDown'>
                <div className='journey-box'>
                  <div className='journey-front'>
                    <h6>Build Strategy</h6>
                    <img
                      src={'/assets/img/home/build-strategy.svg'}
                      alt='Build Strategy'
                    />
                  </div>
                  <div className='servicesHover'>
                    <h6>Build Strategy</h6>
                    <p>
                      Design a plan for reaching net-zero, tailored to your
                      business.
                    </p>
                    <a href='/build-strategy' className='btn btn-link'>
                      Learn More <GoArrowRight />
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={4} lg={2} className='mt-4 mt-lg-0 wow fadeInUp'>
                <div className='journey-box'>
                  <div className='journey-front'>
                    <h6>Reduce Emissions</h6>
                    <img
                      src={'/assets/img/home/reduce.svg'}
                      alt='Reduce Emissions'
                    />
                  </div>
                  <div className='servicesHover'>
                    <h6>Reduce Emissions</h6>
                    <p>Find custom solutions that fit your goals and budget.</p>
                    <a href='/reduce-emissions' className='btn btn-link'>
                      Learn More <GoArrowRight />
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={4} lg={2} className='mt-4 mt-lg-0 wow fadeInDown'>
                <div className='journey-box'>
                  <div className='journey-front'>
                    <h6>Improve Record</h6>
                    <img
                      src={'/assets/img/home/improve-record.svg'}
                      alt='Improve Record'
                    />
                  </div>
                  <div className='servicesHover'>
                    <h6>Improve Record</h6>
                    <p>
                      Track progress and adjust your approach to stay on target.
                    </p>
                    <a href='/improve-strategy' className='btn btn-link'>
                      Learn More <GoArrowRight />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </section>

          <section className='section-padd-bottom trustedby'>
            <h6>Trusted By</h6>

            <div className='clients-section'>
              <div className='slider'>
                <div className='slide-track'>
                  {[...brandLogos, ...brandLogos].map((logo, index) => (
                    <div className='slide' key={index}>
                      <img
                        src={LOGOS_BASE_URL + logo.image}
                        alt={`Client ${index + 1}`}
                      />
                    </div>
                  ))}

                  {/* <div className="slide"><img src={'/assets/img/clients/c1.png'} alt='Client 1' /></div>
                                    <div className="slide"><img src={'/assets/img/clients/c2.png'} alt='Client 2' /></div>
                                    <div className="slide"><img src={'/assets/img/clients/c3.png'} alt='Client 3' /></div>
                                    <div className="slide"><img src={'/assets/img/clients/c4.png'} alt='Client 4' /></div>
                                    <div className="slide"><img src={'/assets/img/clients/c5.png'} alt='Client 5' /></div>

                                    <div className="slide"><img src={'/assets/img/clients/c1.png'} alt='Client 1' /></div>
                                    <div className="slide"><img src={'/assets/img/clients/c2.png'} alt='Client 2' /></div>
                                    <div className="slide"><img src={'/assets/img/clients/c3.png'} alt='Client 3' /></div>
                                    <div className="slide"><img src={'/assets/img/clients/c4.png'} alt='Client 4' /></div>
                                    <div className="slide"><img src={'/assets/img/clients/c5.png'} alt='Client 1' /></div> */}
                </div>
              </div>
            </div>
          </section>

          <section className='section-padd-bottom whoweare'>
            <Row>
              <Col lg={4} className='wow fadeInUp'>
                <div className='decar-platform'>
                  <h6 className='mb-4'>The Decarbonization Platform</h6>
                  <img
                    src={'/assets/img/home/sustainbility.webp'}
                    className='img-fluid'
                    alt='Decarbonization'
                  />
                </div>
              </Col>
              <Col lg={1}></Col>
              <Col lg={7} className='mt-5 mt-lg-0 wow fadeInDown'>
                <h2 className='mb-3'>
                  The trusted sustainability solution for your net-zero journey
                </h2>
                <p className='mb-4'>
                  Our platform uses AI and automation to help you navigate
                  environmental compliance, create efficient sustainability
                  strategies, and improve your business's performance. We guide
                  you through the journey to net-zero with clear, actionable
                  steps.
                </p>
                <a href='/book-a-demo' className='btn btn-primary px-4'>
                  Explore our solutions
                </a>
              </Col>
            </Row>
          </section>

          <section className='section-padd-bottom opportunity-section'>
            <div className='oppor-left-home'>
              <Row>
                <Col lg={8}>
                  <div className=''>
                    <h6 className='mb-4'>The Opportunity</h6>
                    <h2>
                      Gain a competitive advantage through decarbonization
                    </h2>
                  </div>
                </Col>
                <Col lg={6}></Col>
              </Row>
            </div>

            {/* <div className='section-padd-bottom mt-5'>
              <OwlCarousel
                items={2}
                className='opportunity-slider'
                nav
                dots
                margin={30}
                responsive={{ 1: { items: 1, dots: false }, 991: { items: 2 } }}
              >
                <div className='clients-box'>
                  <Row>
                    <Col md={4}>
                      <div className='client-box-head'>
                        <div className='client-logos'>85%</div>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className='clients-content'>
                        <h5 className='pb-2'>Return on Investment</h5>
                        <div className='text-oppor'>
                          85% of investors consider ESG factors in their
                          investment decisions.
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className='clients-box'>
                  <Row>
                    <Col md={4}>
                      <div className='client-box-head'>
                        <div className='client-logos'>2.6%</div>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className='clients-content'>
                        <h5 className='pb-2'>Shareholder Value</h5>
                        <div className='text-oppor'>
                          Companies that consistently scored high on ESG factors
                          saw 2.6x greater shareholder returns than average
                          scorers.
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className='clients-box'>
                  <Row>
                    <Col md={4}>
                      <div className='client-box-head'>
                        <div className='client-logos'>88%</div>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className='clients-content'>
                        <h5 className='pb-2'>Customer Loyalty</h5>
                        <div className='text-oppor'>
                          88% of customers will be more loyal to a company that
                          supports social and environmental issues.
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className='clients-box'>
                  <Row>
                    <Col md={4}>
                      <div className='client-box-head'>
                        <div className='client-logos'>60%</div>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className='clients-content'>
                        <h5 className='pb-2'>Operational Cost</h5>
                        <div className='text-oppor'>
                          Companies can achieve a 60% reduction in operational
                          costs with a focus on sustainability parameters.
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </OwlCarousel>
            </div> */}
          </section>

          <section className='section-padd-bottom opportunity-section'>
            <div className='whyus-box'>
              <h6 className='mb-4 wow fadeInUp'>Why US</h6>
              <Row>
                <Col lg={6} className='order-lg-last wow fadeInUp'>
                  <img
                    src={'/assets/img/products/1.webp'}
                    className='img-fluid rounded-4'
                    alt='Science-Based Decarbonization'
                  />
                </Col>
                <Col
                  lg={6}
                  className='mt-5 mt-lg-0 wow fadeInDown pe-3 pe-lg-0 pe-lg-5'
                >
                  <div className='icon-heading'>
                    <h4>
                      <div className='circle-img'>
                        <span>
                          <img
                            src={
                              '/assets/img/icons/science-based-decarbonization.svg'
                            }
                            className='img-fluid'
                            alt='Decarbonization'
                          />
                        </span>
                      </div>{' '}
                      Science-Based Decarbonization
                    </h4>
                  </div>
                  <p className='my-5'>
                    We rely on proven scientific data to help you reduce your
                    carbon footprint. Our platform offers accurate measurements
                    and reliable strategies to support your sustainability
                    goals.
                  </p>

                  {/* <div className='d-flex align-items-center'>
                    <div className='why-logo me-3'><img src={'/assets/img/home/cc.png'} className="img-fluid" alt='Decarbonization'/></div>
                    <div className='why-logo'><img src={'/assets/img/home/cc2.png'} className="img-fluid" alt='Decarbonization'/></div>
                  </div> */}
                </Col>
              </Row>
            </div>

            <div className='whyus-box section-padd-top'>
              <Row>
                <Col lg={6} className='wow fadeInUp'>
                  <img
                    src={'/assets/img/products/2.webp'}
                    className='img-fluid rounded-4'
                    alt='Cost and Time Savings'
                  />
                </Col>
                <Col
                  lg={6}
                  className='mt-5 ps-3 ps-lg-0 ps-lg-5 mt-lg-0 wow fadeInDown'
                >
                  <div className='icon-heading'>
                    <h4>
                      <div className='circle-img'>
                        <span>
                          <img
                            src={'/assets/img/icons/cost-time-savings.svg'}
                            className='img-fluid'
                            alt='Cost Saving'
                          />
                        </span>
                      </div>{' '}
                      Cost and Time Savings
                    </h4>
                  </div>
                  <p className='my-5'>
                    Our software automates key processes, reducing manual work
                    and helping you save operational costs. This lets you focus
                    on implementing real changes rather than getting bogged down
                    in complex calculations.
                  </p>
                </Col>
              </Row>
            </div>

            <div className='whyus-box section-padd-top'>
              <Row>
                <Col lg={6} className='order-lg-last wow fadeInUp'>
                  <img
                    src={'/assets/img/products/3.webp'}
                    className='img-fluid rounded-4'
                    alt='Continuous Support and Resources'
                  />
                </Col>
                <Col
                  lg={6}
                  className='mt-5 mt-lg-0 wow fadeInDown pe-3 pe-lg-0 pe-lg-5'
                >
                  <div className='icon-heading'>
                    <h4>
                      <div className='circle-img'>
                        <span>
                          <img
                            src={
                              '/assets/img/icons/continuous-support-resources.svg'
                            }
                            className='img-fluid'
                            alt='Pod'
                          />
                        </span>
                      </div>{' '}
                      Continuous Support and Resources
                    </h4>
                  </div>
                  <p className='my-5'>
                    We're with you throughout your decarbonization journey. Our
                    platform provides comprehensive resources, and our support
                    team is available to assist with any questions or challenges
                    you encounter along the way.
                  </p>
                </Col>
              </Row>
            </div>
          </section>

          <section className='section-padd-bottom ourclients-section'>
            <h6 className='mb-4 wow fadeInUp'>Our Clients</h6>
            <Row className='wow fadeInUp'>
              <Col md={7}>
                <h2>
                  Join these pioneering companies on the path to true net-zero.
                </h2>
              </Col>
            </Row>

            <div className='clients-container mt-5 wow fadeInUp'>
              {casestudy.length > 0 && (
                <OwlCarousel
                  items={2}
                  className='clients-slider'
                  nav
                  dots
                  margin={10}
                  responsive={{
                    1: { items: 1, dots: false },
                    991: { items: 2 }
                  }}
                >
                  {casestudy.map((blog) => (
                    <div className='clients-box' key={blog.id + 0}>
                      <Row>
                        <Col md={5}>
                          <div className='client-box-head'>
                            <div className='client-logos'>
                              <img
                                src={COVER_IMAGE_BASE_URL + blog?.coverimg}
                                className='img-fluid'
                                alt='logo2'
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={7}>
                          <div className='clients-content'>
                            <span className='clients-profile-tag'>
                              {
                                subCategories.find(
                                  (sc) => sc.id === parseInt(blog.subcategory)
                                )?.name
                              }
                            </span>
                            <p className='mb-0  mt-4'>{blog.title}</p>
                            <a
                              href={`/article/${blog.slug}`}
                              className='btn btn-outline-primary mt-3 px-4'
                            >
                              Case Study <GoArrowRight />
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}

                  {/* <div className='clients-box'>
                                    <Row>
                                        <Col md={5}>
                                            <div className='client-box-head'>
                                                <div className='client-logos'><img src={COVER_IMAGE_BASE_URL + blog?.coverimg} className="img-fluid" alt='logo2' /></div>
                                            </div>
                                        </Col>
                                        <Col md={7}>
                                            <div className='clients-content'>
                                                <span className='clients-profile-tag'>Travel</span>
                                                <p className='mb-0 mt-4'>Calculating their fleet emissions using fuel, mileage, or estimated data.</p>
                                                <a href='/casestudy' className='btn btn-outline-primary mt-3 px-4'>Case Study <GoArrowRight /></a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='clients-box'>
                                    <Row>
                                        <Col md={5}>
                                            <div className='client-box-head'>
                                                <div className='client-logos'><img src={'/assets/img/clients/c3.png'} className="img-fluid" alt='logo3' /></div>
                                            </div>
                                        </Col>
                                        <Col md={7}>
                                            <div className='clients-content'>
                                                <span className='clients-profile-tag'>Travel</span>
                                                <p className='mb-0  mt-4'>Automating data integration and management solutions via key custom APIs.</p>
                                                <a href='/casestudy' className='btn btn-outline-primary mt-3 px-4'>Case Study <GoArrowRight /></a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='clients-box'>
                                    <Row>
                                        <Col md={5}>
                                            <div className='client-box-head'>
                                                <div className='client-logos'><img src={'/assets/img/clients/c4.png'} className="img-fluid" alt='logo4' /></div>
                                            </div>
                                        </Col>
                                        <Col md={7}>
                                            <div className='clients-content'>
                                                <span className='clients-profile-tag'>{subCategories.find(sc => sc.id === parseInt(blog.subcategory))?.name}</span>
                                                <p className='mb-0  mt-4'>{blog.title}</p>
                                                <a href='/casestudy' className='btn btn-outline-primary mt-3 px-4'>Case Study <GoArrowRight /></a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div> */}
                </OwlCarousel>
              )}
            </div>
          </section>

          <section className='section-padd-bottom pt-5 recources-section wow fadeInUp'>
            <h6 className='wow fadeInUp mb-4'>Our Resources</h6>
            <Row className='wow fadeInUp mb-5'>
              <Col md={8}>
                <h2>Simplifying the decarbonization process for you.</h2>
              </Col>
            </Row>
            {resoucesBlog.length > 0 && (
              <OwlCarousel
                items={4}
                className='recources-slider'
                nav
                dots
                margin={20}
                responsive={{ 1: { items: 1, dots: false }, 991: { items: 4 } }}
              >
                {resoucesBlog.length > 0 &&
                  resoucesBlog.map((blog) => (
                    <div className='recources-box' key={blog.id + 0}>
                      <a href={`/article/${blog.slug}`}>
                        <div className='re-img'>
                          <img
                            src={COVER_IMAGE_BASE_URL + blog?.coverimg}
                            className='img-fluid'
                            alt=''
                          />
                        </div>
                        <div className='re-text mt-3'>
                          <Badge>
                            {
                              subCategories.find(
                                (sc) => sc.id === parseInt(blog.subcategory)
                              )?.name
                            }
                          </Badge>
                          <h5 className='mt-3'>{blog.title}</h5>
                        </div>
                      </a>
                    </div>
                  ))}

                {/* <div className='recources-box'>
                                <a href='/'>
                                    <div className='re-img'><img src={'/assets/img/p-5001.webp'} className="img-fluid" alt='' /></div>
                                    <div className='re-text mt-3'>
                                        <Badge>Academy</Badge>
                                        <h5 className='mt-3'>Learn the language of corporate sustainability from A to Z</h5>
                                    </div>
                                </a>
                            </div>

                            <div className='recources-box'>
                                <a href='/'>
                                    <div className='re-img'><img src={'/assets/img/p-502.webp'} className="img-fluid" alt='' /></div>
                                    <div className='re-text mt-3'>
                                        <Badge>Academy</Badge>
                                        <h5 className='mt-3'>Dive into reports on business, ESG, and sustainability</h5>
                                    </div>
                                </a>
                            </div>

                            <div className='recources-box'>
                                <a href='/'>
                                    <div className='re-img'><img src={'/assets/img/p-504.webp'} className="img-fluid" alt='' /></div>
                                    <div className='re-text mt-3'>
                                        <Badge>Academy</Badge>
                                        <h5 className='mt-3'>Discover which climate and ESG policies are relevant to your company</h5>
                                    </div>
                                </a>
                            </div>
                            <div className='recources-box'>
                                <a href='/'>
                                    <div className='re-img'><img src={'/assets/img/p-504.webp'} className="img-fluid" alt='' /></div>
                                    <div className='re-text mt-3'>
                                        <Badge>Academy</Badge>
                                        <h5 className='mt-3'>Discover which climate and ESG policies are relevant to your company</h5>
                                    </div>
                                </a>
                            </div> */}
              </OwlCarousel>
            )}
          </section>
        </Container>

        <section className='section-padd cta-home cta-section'>
          <Container>
            <div className='text-center cta-text wow fadeInUp'>
              <h2 className='mb-4 mb-lg-5'>
                Begin your decarbonization journey today
              </h2>
              <p>
                Our sustainability experts will advise you on an effective
                strategy for your business.
              </p>
              <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>
                Book A Demo
              </a>
            </div>
          </Container>
        </section>
      </div>
      <div className='fixedbg'>
        <img src={'/assets/img/fixed-bg.webp'} alt='Fixed BG' />
      </div>

      <Footer />
    </div>
  );
}

export default Home;
