import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { GoArrowRight } from "react-icons/go";
import { useLocation } from "react-router-dom";
import Header from '../../../shared/header/Header';
import Footer from '../../../shared/footer/Footer';
import './ProductDetails.scss';
import { COVER_IMAGE_BASE_URL } from '../../../services/constants';
import { BlogApiService, SubCategoryApiService } from '../../../services/apiService';

function ReduceEmissions() {
    let location = useLocation();

    const [casestudy, setCaseStudy] = useState([]);
    const [subCategories, setSubCategories] = useState([])

    const getCaseStudyBlog = async () => {
        BlogApiService.getCaseStudyTopThree()
            .then(async (res) => {
                if (res.data) {
                    setCaseStudy(await res.data.blog);
              
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    const getSubCategories = () => {
        SubCategoryApiService.getSubCategories()
            .then(response => {
                if (response.data.success === true) {
                    setSubCategories(response.data.data)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }
    useEffect(() => {
        getSubCategories();
        getCaseStudyBlog();
    }, [])
    return (
        <div className='products-container ll-logo-display'>

            <Header />

            <div className='inner-hero'>
                <Container>
                    <div className='about-hero-txt'>
                        <div className='p-details-nav'>
                            <Row className='justify-content-center'>
                                <Col xs={4} md={3} lg={2}>
                                    <a className={`btn-primary ${location.pathname === "/collect-data" ? "active" : ""} btn rounded-pill`} href='/collect-data'>01 Collect</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/analyze-data' className={`btn-primary ${location.pathname === "/analyze-data" ? "active" : ""} btn rounded-pill`}>02 Analyze</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/report-data' className={`btn-primary ${location.pathname === "/report-data" ? "active" : ""} btn rounded-pill`}>03 Report</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/build-strategy' className={`btn-primary ${location.pathname === "/build-strategy" ? "active" : ""} btn rounded-pill`}>04 Build</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/reduce-emissions' className={`btn-primary ${location.pathname === "/reduce-emissions" ? "active" : ""} btn rounded-pill`}>05 Reduce</a>
                                </Col>
                                <Col xs={4} md={3} lg={2}>
                                    <a href='/improve-strategy' className={`btn-primary ${location.pathname === "/improve-strategy" ? "active" : ""} btn rounded-pill`}>06 Improve</a>
                                </Col>
                            </Row>
                        </div>
                        <Row className='justify-content-center text-center'>
                            <Col lg={7} className='wow fadeInUp mt-5 mt-lg-0'>
                                <h1 className=''>Take Action to Reduce Your Carbon Footprint</h1>
                                <p className='mt-4'>Implement tailored reduction plans and take concrete steps toward achieving your net-zero goals. Our platform provides curated solutions to help you reduce emissions effectively.</p>
                                <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Book A Demo</a>
                            </Col>
                            <Col lg="11" className='text-center pt-5'>
                                <img src={'/assets/img/products/collect-data.png'} className='img-fluid details-img' alt='Products banner' />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            {/* <section className='trustedby'>
                <div className='clients-section'>
                <div className="slider">
                    <div className="slide-track">
                        <div className="slide"><img src={'/assets/img/clients/c1.png'} alt='Client 1'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c2.png'} alt='Client 2'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c3.png'} alt='Client 3'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c4.png'} alt='Client 4'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c5.png'} alt='Client 5'/></div>

                        <div className="slide"><img src={'/assets/img/clients/c1.png'} alt='Client 1'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c2.png'} alt='Client 2'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c3.png'} alt='Client 3'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c4.png'} alt='Client 4'/></div>
                        <div className="slide"><img src={'/assets/img/clients/c5.png'} alt='Client 1'/></div>
                    </div>
                </div>
                </div>
            </section> */}

            <section className='section-padd products-details-sec trans-bg'>
                <Container>
                    <Row>
                        <Col lg={5} className='wow fadeInRight order-lg-last'>
                            <img src={'/assets/img/products/reduce-emissions.svg'} className='img-fluid' alt='Collect Data' />
                        </Col>
                        <Col lg={7} className='align-self-center mt-5 mt-lg-0 wow fadeInUp'>
                            <h6 className='mb-3'>Introduction</h6>
                            <h2>Reducing emissions is about more than just pledges; it requires concrete actions to demonstrate a credible commitment to sustainability.</h2>
                            <p className='mt-4'>Bharat Carbon helps you turn climate targets and net-zero goals into real-world progress with tailored reduction plans designed to meet your company's specific needs. Our platform offers a wide range of solutions, from carbon credits to unique action cards, allowing you to implement effective strategies to reduce emissions.</p>
                        </Col>
                        {/* <Col lg={1}></Col> */}
                    </Row>
                </Container>
            </section>

            <section className='section-padd-bottom savetime-section products-details-sec'>
                <Container>
                    <Row className='wow fadeInUp'>
                        <Col md={9}>
                            <h6 className='mb-4'>Our Solution</h6>
                            <h2>Our platform offers a comprehensive approach to reducing emissions, providing tailored reduction plans that include specific decarbonization levers:</h2>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col md={4} className='wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>01</div>
                                        Reduce your carbon footprint through selection of tailored decarbonization levers provided by sustainability experts that can best address your company's specific needs.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-4 mt-md-0 wow fadeInDown'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>02</div>
                                        Kickstart your company’s reduction plan with our dedicated Action Cards. Simply select them and create a roadmap to reach net-zero by your respective target.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className='mt-4 mt-md-0 wow fadeInUp'>
                            <Card className='border-0'>
                                <Card.Body className='p-0'>
                                    <Card.Text>
                                        <div className='card-no-n'>03</div>
                                        Track your progress on your dashboard and gain valuable insights with projections of your future emissions.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='section-padd-bottom opportunity-section'>
                <Container>
                    <div className='details-slide'>
                        <Row>
                            <Col lg={6}>
                                <div className='oppor-left'>
                                    <h2>Why Is Reducing Your Carbon Footprint Important?</h2>
                                    <p className='mt-4'>Reducing emissions is crucial for several reasons:</p>
                                    <a href='/book-a-demo' className='btn btn-primary px-4 mt-5'>Start reducing emissions </a>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='opportunity-box wow fadeInUp mt-0'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/cut-CO2-cut-cost.svg'} className="img-fluid" alt='Cut CO2, Cut Costs' /></div>
                                            <h5 className='pb-3'>Cut CO2, Cut Costs</h5>

                                            <div className='text-oppor'>Reducing your carbon footprint can lead to significant long-term cost savings by lowering operational expenses and reducing carbon compensation costs.</div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='opportunity-box wow fadeInUp'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/avoid-greenwashing.svg'} className="img-fluid" alt='Avoid Greenwashing' /></div>
                                            <h5 className='pb-3'>Avoid Greenwashing</h5>

                                            <div className='text-oppor'>Taking real climate action strengthens your company's credibility and shows sustainable leadership. This helps you avoid accusations of greenwashing and builds trust with stakeholders.</div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='opportunity-box wow fadeInUp'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/climate-compliance.svg'} className="img-fluid" alt='Climate Compliance' /></div>
                                            <h5 className='pb-3'>Climate Compliance</h5>
                                            <div className='text-oppor'>As carbon taxes and environmental regulations increase, reducing emissions helps you avoid potential fines and future litigation. The cost of carbon has risen significantly in recent years, highlighting the need for proactive action.</div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='opportunity-box wow fadeInUp'>
                                    <Row className='mt-2'>
                                        <Col sm={12} className='align-self-center'>
                                            <div className='no-icon-c'><img src={'/assets/img/icons/improve-employer-branding.svg'} className="img-fluid" alt='Improve Employer Branding' /></div>
                                            <h5 className='pb-3'>Improve Employer Branding</h5>
                                            <div className='text-oppor'>Companies that implement a strong sustainability strategy often see improved employee productivity and reduced turnover. A net-zero strategy can boost retention rates, motivation, and overall workplace satisfaction.</div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>

            </section>

            <section className='section-padd cta-section'>
                <Container>
                    <div className='text-center cta-text wow fadeInUp'>
                        <h2 className='mb-4 mb-lg-5'>Begin your decarbonization journey today</h2>
                        <p>Our sustainability experts will advise you on an effective strategy for your business.</p>
                        <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Book A Demo</a>
                    </div>
                </Container>
            </section>

            <section className='section-padd exploreStory'>
                <Container>
                    <h6 className='mb-4'>CASE STUDIES</h6>
                    {casestudy.length > 0 && <Row>
                        {casestudy.map((blog) => (<Col lg={4} className='mt-5 wow fadeInUp'>
                            <div className='casestudy-box'>
                                <div className='casestudy-image'>
                                    <img src={COVER_IMAGE_BASE_URL + blog?.coverimg} className='img-fluid' alt='case Study' />
                                </div>
                                <div className='casestudy-text'>
                                    <h6>{subCategories.find(sc => sc.id === parseInt(blog.subcategory))?.name}</h6>
                                    <h5>{blog.title}</h5>
                                    <a href={`/article/${blog.slug}`} className='btn btn-outline-primary px-4 mt-2 mt-lg-4'>Read Case Study <GoArrowRight /></a>
                                </div>
                            </div>
                        </Col>
                        ))
                        }
                    </Row>}
                </Container>
            </section>

            <section className='footerpro-links'>
                <Container>
                    <div className='p-details-nav'>
                        <Row className='justify-content-center'>
                            <Col xs={4} md={3} lg={2}>
                                <a className={`btn-primary ${location.pathname === "/collect-data" ? "active" : ""} btn rounded-pill`} href='/collect-data'>01 Collect</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/analyze-data' className={`btn-primary ${location.pathname === "/analyze-data" ? "active" : ""} btn rounded-pill`}>02 Analyze</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/report-data' className={`btn-primary ${location.pathname === "/report-data" ? "active" : ""} btn rounded-pill`}>03 Report</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/build-strategy' className={`btn-primary ${location.pathname === "/build-strategy" ? "active" : ""} btn rounded-pill`}>04 Build</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/reduce-emissions' className={`btn-primary ${location.pathname === "/reduce-emissions" ? "active" : ""} btn rounded-pill`}>05 Reduce</a>
                            </Col>
                            <Col xs={4} md={3} lg={2}>
                                <a href='/improve-strategy' className={`btn-primary ${location.pathname === "/improve-strategy" ? "active" : ""} btn rounded-pill`}>06 Improve</a>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <Footer />

        </div>
    )
}

export default ReduceEmissions