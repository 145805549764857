import React from 'react';
import logo from '../../logo.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaFacebookF } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import './Footer.scss';

function Footer() {
  return (
    <footer className='Footer-container'>
      <Container>
        <Row>
          <Col lg={3}>
            <img src={logo} className='App-logo' alt='logo' />
          </Col>
          <Col lg={9} className='mt-5 mt-lg-0'>
            <Row>
              <Col md={3}>
                <h5>Our Products</h5>
                <div className='footer-links'>
                  <ul className='list-unstyled'>
                    <li>
                      <a href='/our-products'>Product overview</a>
                    </li>
                    <li>
                      <a href='/collect-data'>Collect data</a>
                    </li>
                    <li>
                      <a href='/analyze-data'>Analyze Data</a>
                    </li>
                    <li>
                      <a href='/report-data'>Report Data</a>
                    </li>
                    <li>
                      <a href='/build-strategy' className='disabled'>
                        Build Strategy <sup>(Coming Soon)</sup>
                      </a>
                    </li>
                    <li>
                      <a href='/reduce-emissions' className='disabled'>
                        Reduce Emissions <sup>(Coming Soon)</sup>
                      </a>
                    </li>
                    <li>
                      <a href='/improve-strategy' className='disabled'>
                        Improve Record <sup>(Coming Soon)</sup>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              {/* <Col md={3}>
                <h5>Our Solutions</h5>
                <div className='footer-links'>
                  <ul className='list-unstyled'>
                    <li>
                      <a href='/our-solutions'>Solutions overview</a>
                    </li>
                    <li>
                      <a href='/IOT-devices'>IOT Devices</a>
                    </li>
                    <li>
                      <a href='/AI-automations'>AI Automation</a>
                    </li>
                  </ul>
                </div>
              </Col> */}
              <Col md={3}>
                <h5>About Us</h5>
                <div className='footer-links'>
                  <ul className='list-unstyled'>
                    <li>
                      <a href='/about-us'>Who are we?</a>
                    </li>
                    <li>
                      <a href='/about-us'>Mission</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3}>
                <h5>Resources</h5>
                <div className='footer-links'>
                  <ul className='list-unstyled'>
                    <li>
                      <a href='/glossary'>Glossary</a>
                    </li>
                    <li>
                      <a href='/articles'>Articles</a>
                    </li>
                    <li>
                      <a href='/articles'>Case Studies</a>
                    </li>
                    {/* <li>
                      <a href='/whitepapers'>Whitepapers</a>
                    </li> */}
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={3} className='mt-4'>
            {/* <h5 className='mb-4'>Certifications & Awards</h5>
            <img src={"/assets/img/home/cc.png"} className="img-fluid" alt="logo" />

            <div className='d-flex mt-3 certified-logos'>
              <img src={"/assets/img/home/bcorp.svg"} alt="logo" />
              <img src={"/assets/img/home/governance logo.png"} alt="logo" />
              <img src={"/assets/img/home/NonCPA.png"} alt="logo" />
            </div> */}
          </Col>
          <Col lg={12} className='mt-5 align-self-end'>
            <h5 className='mb-4 mb-lg-5'>Offices</h5>
            <Row>
              <Col md={4}>
                <h6>Mumbai, India</h6>
                <p className='mb-0'>
                  Spectrum Towers, Mindspace, Malad (W), Mumbai, Maharashtra -
                  400064
                </p>
              </Col>
              <Col md={4} className='mt-4 mt-md-0'>
                <h6>Pune, India</h6>
                <p className='mb-0'>
                  Sai Hira, Mundhwa, Pune, Maharashtra - 411036
                </p>
              </Col>
              <Col md={4} className='mt-4 mt-md-0'>
                <h6>New Jersey, USA</h6>
                <p className='mb-0'>
                  320 South Broad Street, Ridgewood, NJ - 07450
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className='footer-bottom'>
          <Row>
            <Col lg={4} className='mt-4 mt-lg-0 order-last order-lg-first'>
              © Bharat Carbon, 2024 | All Rights Reserved
            </Col>
            <Col lg={6}>
              <div className='footerlinks-bottom'>
                {/* <a href='/'>Legal Notice</a> */}
                <a href='/terms-conditions'>Terms & Conditions</a>
                <a href='/privacy-policy'>Privacy Policy</a>
              </div>
            </Col>
            <Col lg={2}>
              <div className='social-links'>
                <a
                  href='https://www.facebook.com/profile.php?id=100090226570336&mibextid=ZbWKwL'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaFacebookF />
                </a>
                <a
                  href='https://x.com/BharatCarbon'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaXTwitter />
                </a>
                <a
                  href='https://in.linkedin.com/company/bharat-carbontech'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaLinkedinIn />
                </a>
                <a
                  href='https://www.instagram.com/bharatcarbon'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaInstagram />
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
