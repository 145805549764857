import React, { useState, useEffect } from 'react';
import logo from '../../logo.svg';
// import logodark from '../../logo-dark.svg';
import {
  Container,
  Row,
  Col,
  NavDropdown,
  Dropdown,
  Nav,
  Navbar
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
// import { IoMdClose } from "react-icons/io";
import './Header.scss';

function Header() {
  let location = useLocation();
  // Add and Remove Class on scroll
  const [scrolltopdata, setscrolltopdata] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 50) {
        setscrolltopdata('');
      } else {
        setscrolltopdata('headerdark');
      }
    });
  }, []);

  const [isToggled, setIsToggled] = useState(false);

  return (
    <div className={isToggled ? 'header-mobile-container' : 'Header-container'}>
      {/* <div className='headertop'>
        Bharat Carbon raises $27M from Lightspeed and leading global financial institutions
      </div> */}
      <header className={`fixed-top ${scrolltopdata}`}>
        <Navbar expand='lg' bg='dark' data-bs-theme='dark'>
          <Container>
            <Navbar.Brand href='/'>
              <img src={logo} className='App-logo logo-light' alt='logo' />
              {/* <img src={logodark} className="App-logo logo-dark" alt="logo" /> */}
            </Navbar.Brand>
            <Navbar.Collapse id='basic-navbar-nav'>
              {/* <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setIsToggled(!isToggled)} /> */}
              <Nav className='me-auto'>
                <Nav.Link
                  className={`nav-link ${
                    location.pathname === '/' ? 'active' : ''
                  }`}
                  href='/'
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  className={`nav-link ${
                    location.pathname === '/about-us' ? 'active' : ''
                  }`}
                  href='/about-us'
                >
                  About Us
                </Nav.Link>
                <NavDropdown
                  title='Our Products'
                  id='basic-nav-dropdown'
                  className={`dropdown ${
                    location.pathname === '/our-products' ? 'active' : ''
                  }`}
                  href='/our-products'
                  renderMenuOnMount={true}
                >
                  <div className='eventsNav pt-0 mt-0'>
                    <Row>
                      <Col xs='12' md='4' className='text-left'>
                        <Nav.Link href='/our-products'>
                          Product Overview
                          <span>
                            Get an overview of our sustainability platform.
                          </span>
                        </Nav.Link>
                      </Col>
                      <Col xs='12' md='8'>
                        <h5>Product Capabilities</h5>
                        <Row>
                          <Col xs='12' md='6' className='text-left'>
                            <Nav.Link href='/collect-data'>
                              Collect data
                              <span>
                                Seamlessly collect and organize your emissions
                                data.
                              </span>
                            </Nav.Link>
                            <Nav.Link href='/analyze-data'>
                              Analyze Data
                              <span>
                                Visualize your carbon footprint at a granular
                                level.
                              </span>
                            </Nav.Link>
                            <Nav.Link href='/report-data'>
                              Report Data
                              <span>
                                Accelerate ESG reports leveraging AI &
                                automation.
                              </span>
                            </Nav.Link>
                            <Dropdown.Divider />
                          </Col>

                          <Col xs='12' md='6' className='text-left'>
                            <Nav.Link href='/build-strategy' disabled>
                              Build Strategy <sup>(Coming Soon)</sup>
                              <span>
                                Build your company's strategy with AI for Net
                                Zero Success.
                              </span>
                            </Nav.Link>
                            <Nav.Link href='/reduce-emissions' disabled>
                              Reduce Emissions <sup>(Coming Soon)</sup>
                              <span>
                                Find personalized solutions that match your
                                company's goals & budgets.
                              </span>
                            </Nav.Link>
                            <Nav.Link href='/improve-strategy' disabled>
                              Improve Record <sup>(Coming Soon)</sup>
                              <span>
                                Track Progress and optimize your decarbonization
                                journey.
                              </span>
                            </Nav.Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </NavDropdown>

                {/* <NavDropdown title="Our Solutions" id="Our-Solutions-dropdown" className={`dropdown ${location.pathname === "/our-solutions" ? "active" : ""}`} href="/our-solutions" renderMenuOnMount={true}>
                        <div className="eventsNav smallevnav pt-0 mt-0">
                          <Row>
                            <Col xs="12" md="6" className="text-left">
                              <Nav.Link href="/our-solutions">
                                Solutions Overview
                                <span>Get an overview of our sustainability platform.</span>
                                </Nav.Link>
                            </Col>
                            <Col xs="12" md="6">
                              <h5>Product Capabilities</h5>
                              <Row>
                                <Col xs="12" md="12" className="text-left">
                                  <Nav.Link href="/IOT-devices">
                                    IOT Devices
                                    <span>Seamlessly collect and organize your emissions data.</span>
                                  </Nav.Link>
                                  <Nav.Link href="/AI-automations">
                                    AI Automation
                                    <span>Build your company's strategy with AI for Net Zero Success.</span>
                                  </Nav.Link>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </NavDropdown> */}

                <NavDropdown
                  title='Resources'
                  id='resources-dropdown'
                  className='dropdown'
                  renderMenuOnMount={true}
                >
                  <div className='eventsNav smallevnav pt-0 mt-0'>
                    <Row>
                      <Col xs='12' md='6' className='text-left'>
                        <h5>KNOWLEDGE</h5>
                        <Nav.Link href='/articles'>
                          Articles
                          <span>Resources on Business & Sustainability</span>
                        </Nav.Link>
                        <Nav.Link href='/articles'>
                          Case Studies
                          <span>Bharat Carbon case study</span>
                        </Nav.Link>
                        {/* <Nav.Link href="/whitepapers">
                                Whitepapers
                                <span>Lorem Ipsum is simply dummy text</span>
                              </Nav.Link> */}
                      </Col>
                      <Col xs='12' md='6'>
                        <h5>DEFINITIONS</h5>
                        <Row>
                          <Col xs='12' md='12' className='text-left'>
                            <Nav.Link href='/glossary'>
                              Glossary
                              <span>
                                Terms and abbreviations about carbon and ESG
                                management
                              </span>
                            </Nav.Link>
                            {/* <Nav.Link href="/">Carbon Accounting</Nav.Link>
                                  <Nav.Link href="/">ESG Reporting</Nav.Link>
                                  <Nav.Link href="/">Net Zero</Nav.Link> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </NavDropdown>

                {/* <Nav.Link className={`nav-link ${location.pathname === "/book-a-demo" ? "active" : ""}`} href="/book-a-demo">Contact Us</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
            <div className='headerRight ms-auto'>
              <a href='/book-a-demo' className='btn btn-primary px-3 px-md-4'>
                Book A Demo
              </a>
              <Navbar.Toggle
                aria-controls='basic-navbar-nav'
                onClick={() => setIsToggled(!isToggled)}
              />
            </div>
          </Container>
        </Navbar>
      </header>
    </div>
  );
}

export default Header;
