import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../../shared/header/Header';
import Footer from '../../shared/footer/Footer';

function TermsConditions() {
  return (
    <div className='terms-container planeHeader'>
      <Header />

      <section className='bookdemo-con section-padd-bottom terms-con'>
        <Container>
          <h3 className='text-center'>General Terms and Conditions</h3>

          <div className='section-padd-top'>
            <h5>1. DEFINITIONS</h5>
            <ul className='list-unstyled liststyled mt-3'>
              <li>
                <strong>1.1.</strong> “Acceptable Use Policy” or “AUP” shall
                have the meaning set forth in Section 2.2
              </li>

              <li>
                <b>1.2.</b> “Accepted” or “Acceptance” means a Party’s
                authorized execution of a document.
              </li>

              <li>
                <b>1.3.</b> “Affiliate” means an entity directly or indirectly
                Controlled by, Controlling or under common Control with a Party,
                now or in the future. An entity shall “Control" another entity
                when it owns more than 50% of the equity or other voting
                interests, or otherwise has primary management or operational
                responsibility.
              </li>

              <li>
                <b>1.4.</b> “Agreement” means (i) these General Terms and
                Conditions, (ii) Service Order and/or Statement of Work executed
                by the Parties; and (iii) any other document that is expressly
                incorporated by reference in this Agreement, a Service Order
                and/or a Statement of Work.
              </li>

              <li>
                <b>1.5.</b> “Change of Control” means one or more transactions
                whereby (a) Control of a Party is transferred, directly or
                indirectly, whether by operation of law or otherwise, (b) all or
                substantially all of such Party’s assets or equity securities
                are acquired by a person, firm or entity or (c) such Party is
                merged or consolidated with or into another entity; provided,
                that, in any case, such Party's equity owners of record
                immediately before such transaction(s) shall, immediately after
                such transaction(s), hold less than 50% of the voting power of
                the succeeding, acquiring or surviving entity.
              </li>

              <li>
                <b>1.6.</b> “Confidential Information” means proprietary,
                nonpublic or trade secret information, disclosed in written,
                oral or visual form, that the disclosing Party, its Affiliates
                or agents (each, a "Disclosing Party") provides to the receiving
                Party, its Affiliates or agents (each, a "Receiving Party") and
                that Disclosing Party designates as being confidential or, if
                disclosed orally, states to be confidential at the time of
                initial disclosure, or if not so marked or stated should
                reasonably have been understood to be confidential as to
                Disclosing Party, either because of the circumstances of
                disclosure or the nature of the information itself and that (a)
                relates to Disclosing Party, its products, services,
                developments, trade secrets, know-how or personnel; and (b) is
                received by Receiving Party from Disclosing Party during the
                Term.
              </li>

              <li>
                <b>1.7.</b> “Customer” means a customer that uses or purchases
                Services pursuant to this Agreement solely for such customer’s
                use. “Customer” includes a Resale Customer as defined herein.
              </li>

              <li>
                <b>1.8.</b> “Customer Content” means any digital assets and data
                provided by Customer and/or Resale Customer to BCV for storage,
                delivery or other processing associated with the Services,
                uploaded or routed to, passed through and/or stored on or within
                BCV's applicable network or otherwise provided to BCV.
              </li>

              <li>
                <b>1.9.</b> “Customer Data” shall have the meaning set forth in
                Section 5.1.
              </li>
              <li>
                <b>1.10.</b> “BCV” means BCV Tech India Pvt Ltd.
              </li>

              <li>
                <b>1.11.</b> “Effective Date” means the effective date of the
                Agreement. Unless otherwise specified in the Service Order
                and/or Statement of Work, the Effective Date is the date on
                which the last party signs the Service Order.
              </li>

              <li>
                <b>1.12.</b> “End User” means a consumer and/or subscriber to,
                member of or other visitor to an online site or service owned
                and/or operated by Customer or Resale Customer who uses,
                benefits from or accesses the Services.
              </li>

              <li>
                <b>1.13.</b> “End User Data” shall have the meaning set forth in
                Section 5.1.
              </li>

              <li>
                <b>1.14.</b> “Force Majeure Event” shall have the meaning set
                forth in Section 14.4.
              </li>

              <li>
                <b>1.15.</b> “Intellectual Property Rights” means all rights in,
                to, or arising out of (a) any U.S., international or foreign
                patent or any application thereof and any and all reissues,
                divisions, continuations, renewals, extensions,
                continuations-in-part, utility models, and supplementary
                protection certificates thereof; (b) inventions (whether
                patentable or not in any country), invention disclosures,
                improvements, trade secrets, proprietary information or
                materials, know-how, technology and technical data; (c)
                copyrights, copyright registrations, mask works, mask work
                registrations, and applications therefor in the U.S. or any
                foreign country, and all other rights corresponding thereto
                throughout the world; (d) trademarks, service marks, trade
                names, domain names, logos, trade dress, and all goodwill
                associated therewith; and (e) any other proprietary rights of a
                similar nature anywhere in the world now or hereafter
                recognized.
              </li>

              <li>
                <b>1.16.</b> “MRC” means the monthly recurring charge for
                Services.
              </li>

              <li>
                <b>1.17.</b> “Open-Source Component” means any software
                component that is subject to any open-source copyright license
                agreement, including any GNU General Public Licensor GNU Library
                or Lesser Public License, or other obligation, restriction or
                license agreement that substantially conforms to the Open-Source
                Definition as prescribed by the Open-Source Initiative or
                otherwise may require disclosure or licensing to any third Party
                of any source code with which such software component is used or
                compiled.
              </li>

              <li>
                <b>1.18.</b> “Parties” means BCV Tech India Pvt Ltd (“BCV”) or
                Customer; “Party “means BCV or Customer.
              </li>

              <li>
                <b>1.19.</b> “Resale Customer” means a Customer that purchases
                Services through a Reseller.
              </li>

              <li>
                <b>1.20.</b> “Reseller” means an entity authorized by BCV to
                sell Services.
              </li>

              <li>
                <b>1.21.</b> “Service Order” or "SO" means a specification of
                Services to be performed by BCV and the associated fees or
                charges, as agreed by the Parties.
              </li>

              <li>
                <b>1.22.</b> “Services” or “Platform/Services” shall have the
                meaning set forth in Section 1 of Exhibit A.
              </li>

              <li>
                <b>1.23.</b> “Service Supplement” means any attachment to this
                Agreement setting forth the description, rates, service levels
                and other applicable terms for the Service identified.
              </li>

              <li>
                <b>1.24.</b> “Statement of Work” or “SOW” means a specification
                of the non-standard (i.e., custom or professional) Services to
                be performed by BCV and the associated fees or charges, as
                agreed by the Parties.
              </li>

              <li>
                <b>1.25.</b> “Taxes” means any applicable taxes, tax-like
                charges, tax-related charges and other charges or surcharges,
                including all excise, use, sales, value-added and other fees,
                surcharges and levies.
              </li>

              <li>
                <b>1.26.</b> “Term” shall have the meaning set forth in Section
                12.1.
              </li>
            </ul>

            <h5>2. SERVICES</h5>
            <ul className='list-unstyled liststyled mt-3'>
              <li>
                <b>2.1. Services.</b> BCV shall provide the Platform/Services to
                Customer as specified in a mutually Accepted SO, SOW or Service
                Supplement, subject to the terms and conditions herein
                (including any terms or conditions in the applicable SO, SOW
                and/or Service Supplement). All SOs and SOWs must be in writing
                and Accepted by an authorized representative of each Party and
                refer to the Agreement by number or by title and date. Customer
                shall not white label or otherwise re-brand the Services for
                resale without advance written consent from BCV.{' '}
              </li>

              <li>
                <b>2.2. Acceptable Use, Technical Cooperation.</b> Customer is
                responsible for and assumes all liabilities arising out of or
                related to Customer Content/Data. Customer acknowledges that BCV
                is a mere intermediary (i.e., passive conduit) for transmission
                and review of Customer Data and does not exercise editorial or
                other control over Customer Data. The applicable BCV Privacy
                Policy ("Privacy Policy") and BCV Acceptable Use Policy ("AUP")
                for the Services are available at www.bharatcarbon.earth/terms/
                or other URL designated by BCV. Customer shall not provide to
                BCV any Data that may compromise the Services, is inappropriate
                and/or violates or is inconsistent with the AUP.{' '}
              </li>

              <li>
                <b>2.3. Use of Affiliates/Suppliers/Subcontractors.</b> BCV may
                at any time, and without notice, use one or more Affiliates,
                suppliers or sub-contractors in connection with the performance
                of its obligations under this Agreement.
              </li>
            </ul>

            <h5>3. CHARGES AND PAYMENT.</h5>
            <ul className='list-unstyled liststyled mt-3'>
              <li>
                <b>3.1. Taxes.</b> All charges are exclusive of Taxes, which
                Customer must pay. If Customer provides BCV with a valid, duly
                executed tax exemption certificate, BCV shall exempt Customer
                from Taxes in accordance with the law, effective on the date BCV
                receives the exemption certificate. If Customer disputes the
                application of any Tax, Customer must give BCV written notice of
                the dispute within one month of the date of the invoice.
                Otherwise, such application of Taxes, as between BCV and
                Customer, shall be deemed correct and binding on Customer. If
                Customer is required by law to make any deduction or withholding
                from any payment due hereunder to BCV, then notwithstanding
                anything to the contrary in this Agreement, the gross amount
                payable by Customer to BCV shall be increased so that, after any
                such deduction or withholding for taxes, the net amount received
                by BCV shall not be less than BCV would have received had no
                such deduction or withholding been required.
              </li>

              <li>
                <b>3.2. Payment.</b>
                <ol className='list-unstyled liststyled ps-3'>
                  <li>
                    <b>a.</b> Payment is due within 15 days of the invoice date.
                    All invoices may be delivered to Customer electronically.
                    Customer shall remit payment to BCV at its principal
                    offices, unless BCV provides notice to Customer otherwise.
                    All charges shall be invoiced and payable in the currency
                    mutually agreed in writing.
                  </li>

                  <li>
                    <b>b.</b> If an invoice is unpaid within 15 days of the
                    invoice date (i.e., the invoice becomes delinquent), BCV
                    may, in its sole discretion and effective upon notice to
                    Customer, (i) suspend Services, (ii) apply a late charge on
                    the unpaid amount equal to the lesser of 1.5% interest per
                    month or the maximum rate allowed by law, (iii) require
                    Customer to provide a cash deposit or other security to
                    guarantee payment and/or (iv) pursue any other remedy
                    available under this Agreement, at law or in equity.
                  </li>

                  <li>
                    <b>c.</b> If Customer desires to dispute in good faith an
                    invoiced amount, Customer shall, within 15 days of the
                    invoice date, (i) pay the invoiced amount and (ii) provide
                    notice of the details of the dispute, together with all
                    supporting documentation. The Parties shall work diligently
                    to promptly resolve the dispute and upon resolution, (1) BCV
                    shall promptly credit to Customer any amount found to be
                    owed to Customer or (2) Customer shall promptly pay to BCV
                    all amounts found to be owed to BCV. If Customer does not
                    timely submit a documented dispute notice per this Section,
                    Customer waives all rights to dispute such amounts,
                    including any claim of set-off or reimbursement.
                  </li>

                  <li>
                    <b>d.</b> If BCV reasonably deems itself insecure with
                    respect to Customer’s ability to pay (e.g., due to
                    Customer's withdrawal of credit card authorization for
                    automatic payment), BCV may, in its sole discretion and
                    effective upon notice to Customer, (i) modify Customer’s
                    payment terms (e.g., by changing from monthly to quarterly
                    invoicing of MRCs, as defined herein) and/or (ii) require
                    Customer to provide a cash deposit or other security to
                    guarantee payment.
                  </li>
                </ol>
              </li>
            </ul>

            <h5>4. GRANT OF RIGHTS, INTELLECTUAL PROPERTY.</h5>
            <ul className='list-unstyled liststyled mt-3'>
              <li>
                <b>4.1.</b> BCV grants to Customer the right to access and use
                the Services during the Term solely for the internal business
                purposes of the Customer. Subject only to the foregoing, BCV
                retains all worldwide rights, title and interest in and to the
                Services, BCV Platform/Services, cloud, equipment, network and
                methodologies, software and Intellectual Property Rights
                embodied therein or related thereto, whenever developed.
                Customer shall not and shall require that Resale Customers do
                not either directly or indirectly, reverse engineer, decompile,
                disassemble or otherwise attempt to derive source code or other
                trade secrets from any Services (including any related software,
                cloud, hardware or information.)
              </li>

              <li>
                <b>4.2.</b> Customer grants to BCV, and its agents, suppliers
                and subcontractors, the right to access and use, upload,
                reproduce, duplicate, format, store, distribute, display and
                perform Customer Data and associated
                reports/invoices/receipts/documents as necessary to provide the
                Services. Subject only to the foregoing, Customer retains all
                rights, title and interest in and to Customer Data.
              </li>
            </ul>

            <h5>5. DATA.</h5>
            <ul className='list-unstyled liststyled mt-3'>
              <li>
                <b>5.1.</b> The Parties acknowledge and agree that (a) BCV, its
                Affiliates and agents shall, by virtue of providing Services,
                come into possession of Customer Data including
                reports/invoices/receipts/documents (b) any processing of such
                data occurs exclusively at the direction and discretion of
                Customer, as exercised through workflows or other agreed means
                and (c) BCV, its Affiliates and agents may use, process and/or
                transfer Customer Data and Customer Data (including transfers to
                entities in countries that do not provide statutory protections
                for personal data) (i) in connection with providing Services and
                (ii) as applicable, to incorporate Customer Data into databases
                controlled by BCV and its Affiliates for the purpose of
                administration, provisioning, invoicing and reconciliation,
                verification of Customer identity and solvency, maintenance,
                support and product development, fraud detection and prevention,
                sales, revenue and Customer analysis and reporting, marketing
                and Customer use analysis. “<b>Customer Data</b>” means
                information that relates to ESG data sources including IOT and
                other data elements captured automatically as required for
                Services of Customer or a Resale Customer.
              </li>

              <li>
                <b>5.2.</b> Use of De-identified Data: Customer grants the
                Service Provider the right to use de-identified data collected
                or derived from Customer's data ("De-identified Data") for the
                purpose of improving its ESG services, developing new products
                or features, conducting research, and performing analytics.
                De-identified Data shall not contain any personally identifiable
                information (PII) or sensitive information that could be used to
                identify individuals or confidential business information.
              </li>

              <li>
                <b>5.3.</b> Data Retention: The Service Provider may retain
                De-identified Data for as long as necessary to fulfill the
                purposes outlined in this Agreement, unless otherwise specified
                by Customer in writing.
              </li>

              <li>
                <b>5.4.</b> Data Processing: In the event that the Service
                Provider processes personal data on behalf of Customer as part
                of the ESG Services, the parties shall enter into a separate
                data processing agreement or addendum, as required by applicable
                data protection laws and regulations.
              </li>

              <li>
                <b>5.5.</b> Indemnification: Customer agrees to indemnify and
                hold harmless the Service Provider from any claims, losses,
                liabilities, damages, and expenses arising out of or related to
                Customer's provision of data under this Agreement, including any
                claims of infringement of third-party rights.
              </li>

              <li>
                <b>5.6.</b> Survival: The obligations and rights of the parties
                under this Data Clause shall survive the termination or
                expiration of this Agreement for any reason.
              </li>
            </ul>

            <h5>6. CONFIDENTIALITY.</h5>
            <div className='mt-3'>
              During the Term and for three years thereafter, Receiving Party
              shall not use, copy or disclose Confidential Information except as
              permitted herein. All copies of Confidential Information remain
              the sole property of Disclosing Party. Receiving Party shall
              protect Disclosing Party’s Confidential Information using at least
              the same procedures as it uses to protect its own Confidential
              Information, but no less than reasonable procedures. Receiving
              Party may disclose Confidential Information to its employees,
              consultants and contractors who have a need to know in connection
              herewith and who have executed a confidentiality agreement at
              least as stringent as that provided in this Section 6 or to
              attorneys or other consultants or contractors who are legally
              required to maintain the confidentiality of such Confidential
              Information. Receiving Party also may disclose Confidential
              Information pursuant to applicable law, regulation, subpoena or
              other order of a court of competent jurisdiction (collectively, “
              <b>Legal Requirement</b>”) or to establish rights or obligations
              under this Agreement in any proceeding; provided, that (1)
              reasonable prior notice, unless legally prohibited, is provided to
              Disclosing Party sufficient to provide Disclosing Party an
              opportunity to contest such disclosure, (2) Receiving Party
              cooperates with Disclosing Party in complying with any applicable
              protective order or equivalent, and (3) Receiving Party discloses
              Confidential Information only to the extent necessary to comply
              with the Legal Requirement or to establish such rights or
              obligations. Receiving Party shall notify Disclosing Party upon
              discovery of any unauthorized use or disclosure of Confidential
              Information and shall cooperate to help Disclosing Party prevent
              further unauthorized use or disclosure. BCV shall not be deemed to
              have received, obtained, discovered, processed, stored,
              maintained, been given access or required access to Customer's
              Confidential Information solely because (x) Customer receives,
              transmits, obtains or otherwise exchanges such information by
              using the Services or (y) BCV's provision of the Services may
              involve hosting, storage, transport or other similar handling of
              such information. Receiving Party acknowledges that Disclosing
              Party’s Confidential Information is valuable and unique, and that
              unauthorized use or disclosure may result in irreparable injury to
              Disclosing Party for which monetary damages are inadequate. If
              Receiving Party violates or threatens to violate this Section 6,
              Disclosing Party shall be entitled to seek injunctive relief
              without the need to post bond, in addition to any other available
              legal or equitable remedies.
            </div>

            <h5>7. REPRESENTATIONS AND WARRANTIES.</h5>
            <ul className='list-unstyled liststyled mt-3'>
              <li>
                <b>7.1.</b> Each Party represents and warrants that: (a) it
                possesses the full right, power and authority to enter into and
                fully perform the Agreement and grant the rights granted herein;
                (b) it has obtained and shall comply with, and make any
                necessary payments due in connection with, all required
                authorizations, approvals, licenses or permits from all third
                Parties, government authorities or otherwise in order for it to
                enter into and perform its obligations herein; (c) it is not
                bound by any contractual or other legal obligation that would
                prevent it from entering into or performing its obligations
                herein; (d) the execution, delivery and performance of this
                Agreement has been duly authorized by all necessary corporate
                action; and (e) it shall comply with all applicable laws, rules
                and regulations in its performance hereunder.
              </li>

              <li>
                <b>7.2.</b> Customer represents and warrants that it has
                obtained and maintains all legally required consents and
                permissions for the use, processing and transfer of Customer
                Dataprovided to BCV.
              </li>

              <li>
                <b>7.3.</b> Customer represents and warrants that none of
                Customer's known End Users are (a) designated on any U.S.
                Government or other governmental list of restricted Parties,
                including the List of Specially Designated Nationals and Blocked
                Persons administered by the Office of Foreign Asset Controls at
                the U.S. Department of the Treasury; (b) located in or otherwise
                ordinarily resident in any country where U.S. or other
                governmental sanctions or embargo provisions prohibit the
                provision of the Services; or (c) otherwise prohibited from
                using, benefiting from or accessing the Services.
              </li>
            </ul>

            <h5>8. DISCLAIMER.</h5>
            <div className='mt-3'>
              BCV PROVIDES THE SERVICES “AS IS” AND DISCLAIMS ALL
              REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
              STATUTORY OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE
              OF TRADE, TO THE FULLEST EXTENT PERMITTED BY LAW.
            </div>

            <h5>9. LIMITATION OF LIABILITY.</h5>
            <div className='mt-3'>
              IN NO EVENT SHALL EITHER PARTY BE LIABLE FOR INDIRECT, INCIDENTAL,
              SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES OF ANY KIND,
              INCLUDING LOST REVENUES, PROFITS OR GOODWILL, LOST OR DAMAGED
              CUSTOMER CONTENT OR DATA, LOST CUSTOMERS, BUSINESS INTERRUPTION OR
              REPLACEMENT SERVICES, HOWEVER CAUSED AND REGARDLESS OF THEORY OF
              LIABILITY, WHETHER OR NOT SUCH PARTY KNEW OR HAD REASON TO KNOW OF
              THE POSSIBILITY OF SUCH DAMAGES AND WHETHER OR NOT THE REMEDIES
              PROVIDED FOR HEREIN FAIL OF THEIR ESSENTIAL PURPOSE, OR WHETHER
              FORESEEABLE OR NOT, ARISING FROM THE PERFORMANCE OR NONPERFORMANCE
              OF THIS AGREEMENT, OR ANY ACTS OR OMISSIONS ASSOCIATED THEREWITH
              OR RELATED TO BCV SYSTEMS, NETWORKS, COMPONENTS OR PROCESSES, ANY
              BCV SERVICES, EQUIPMENT, SOFTWARE OR DOCUMENTATION. BCV SHALL HAVE
              NO LIABILITY FOR DATA THEFT, DENIAL OF SERVICE ATTACKS OR OTHER
              MALICIOUS ACTS BY THIRD PARTIES. BCV’S AGGREGATE LIABILITY FOR ANY
              AND ALL CAUSES OF ACTIONS, CLAIMS AND DAMAGES IN CONNECTION WITH
              THIS AGREEMENT IS LIMITED TO THE LESSER OF (1) DIRECT DAMAGES
              PROVEN BY CUSTOMER OR (2) THE AMOUNT OF FEES OR CHARGES PAID BY
              CUSTOMER TO BCV DURING THE 6-MONTH PERIOD BEFORE THE DATE ON WHICH
              ANY CLAIM AROSE.
            </div>

            <h5>10. INDEMNIFICATION.</h5>
            <ul className='list-unstyled liststyled mt-3'>
              <li>
                <b>10.1. Indemnification.</b> Customer shall, at its cost,
                defend, indemnify and hold harmless BCV and its officers,
                directors, employees, agents and permitted successors and
                assigns (each a "BCV Indemnitee") through final judgment or
                settlement, from and against any third-Party claim, action,
                suit, proceeding, judgments, settlements, losses, damages,
                expenses (including reasonable legal fees and expenses) and
                costs (including allocable costs of in-house counsel) ("Claim")
                brought against a BCV Indemnitee arising out of or based upon
                (a) bodily injury, death or loss of or damage to real or
                tangible personal property to the extent that such Claims were
                alleged to have been proximately caused by any negligent act,
                omission or willful misconduct of Customer, its agents or
                employees, (b) operation or use of Customer’s or Resale
                Customer’s Data (c) unauthorized use of or access to the
                Services or BCV Platform/Services by Customer or Resale
                Customer, (d) BCV’s compliance with Customer or Resale Customer
                specifications, (e) a combination or modification of the
                Services or BCV Platform/Services by or on behalf of Customer or
                Resale Customer by anyone other than BCV or its authorized
                agents, or (f) use of other than the then-current, unaltered
                release of any BCV-provided software used in the Service.
              </li>

              <li>
                <b>10.2. Process.</b> The BCV Indemnitee shall (a) promptly
                provide notice to Customer of any Claim for which indemnity is
                claimed (provided, that, any delay in providing notice shall not
                relieve Customer of its obligations hereunder, except to the
                extent that Customer is materially prejudiced by such delay),
                (b) permit Customer to control the defense of any such Claim and
                (c) provide reasonable assistance at Customer's reasonable cost.
                Subject to the foregoing, in any Claim for which indemnification
                is sought, Customer may select legal counsel to represent the
                BCV Indemnitee (such counsel to be reasonably satisfactory to
                the BCV Indemnitee) and to otherwise control the defense. If
                Customer elects to control the defense, the BCV Indemnitee may
                fully participate in the defense at its own cost. If Customer,
                within a reasonable time after receipt of notice of Claim, fails
                to defend the BCV Indemnitee, the BCV Indemnitee may defend and
                compromise or settle the Claim at Customer's cost.
                Notwithstanding the foregoing, Customer may not consent to entry
                of any judgment or enter into any settlement that imposes
                liability or obligations on the BCV Indemnitee or diminishes the
                BCV Indemnitee's rights, without obtaining the BCV Indemnitee's
                express prior consent, such consent not to be unreasonably
                withheld or delayed, other than cessation of infringing
                activity, confidential treatment of the settlement, and/or
                payment of money that is fully indemnified by Customer under
                this Agreement.
              </li>

              <li>
                <b>
                  10.3. REMEDIES IN EVENT OF INFRINGEMENT OF INTELLECTUAL
                  PROPERTY RIGHTS.{' '}
                </b>{' '}
                In the event any portion of the Platform/Services is held or
                believed by BCV’ to infringe Intellectual Property Rights of any
                third party (such portion to be deemed the “Infringing
                Materials), BCV’ shall, at its sole expense and at its option
                (a) obtain from such third party the right for Customer to
                continue to use the Infringing Materials; (b) modify the
                Infringing Materials to avoid and eliminate such infringement;
                (c) upon mutual agreement with Customer, remove and disable the
                Infringing Materials; or (d) if none of the foregoing remedies
                is commercially feasible, terminate this Agreement. This Section
                11 contains Customer’s sole and exclusive remedy and BCV’s
                entire liability (except as provided by Section 10.2 for acts
                occurring prior to the assertion of the Claim), with respect to
                infringement or alleged infringement of third-party Intellectual
                Property Rights.
              </li>
            </ul>

            <h5>11. Branding.</h5>
            <ul className='list-unstyled liststyled mt-3'>
              <li>
                <b>a.</b> BCV shall have the right to refer to Customer as a
                Customer of BCV Services. Customer can use BCV/BCV’s name, logo,
                trade name, service marks, trademarks or printed material in any
                promotional or advertising material, statement, document, press
                release or broadcast with BCV’s prior written consent.
              </li>

              <li>
                <b>b.</b> Customer covenants and agrees that it: (i) will not
                use, register or otherwise assert any ownership interest in any
                mark that in BCV’s reasonable judgment is confusingly similar to
                BCV’s trademarks or any portion thereof; (ii) will not use BCV’s
                trademarks in any manner whatsoever which may, in BCV’s
                reasonable judgment, jeopardize BCV’s ownership or the
                significance, distinctiveness, validity or value of BCV’s
                trademarks; and (iii) will not contest the validity of any of
                BCV’s trademarks.
              </li>

              <li>
                <b>c.</b> Customer agrees that any and all rights, interests and
                goodwill that might be acquired by its use of BCV’s trademarks
                shall inure to the sole benefit of BCV. Customer acknowledges
                and agrees that, as between itself and BCV, it neither has nor
                will assert any ownership rights or other interests in BCV’s
                trademarks other than the limited rights of use expressly
                provided herein.
              </li>

              <li>
                <b>d.</b> Customer agrees to comply with rules set forth from
                time to time by BCV with respect to the appearance and manner of
                use of BCV’s trademarks.
              </li>

              <li>
                <b>e.</b> Customer shall comply with all laws and regulations
                pertaining to the proper use and designation of marks and shall
                in advertising and printed materials identify BCV’s trademarks
                with such notice as may be specified by BCV in writing.
              </li>

              <li>
                <b>f.</b> Unless otherwise set forth in this Agreement or as
                otherwise expressly agreed by the Parties in writing, upon the
                effective date of expiration or any termination of this
                Agreement, all licenses and rights granted pursuant to this
                Section 11 shall cease and terminate forthwith and immediately
                revert to BCV, and Customer shall discontinue all use of BCV’s
                trademarks and shall destroy or return all materials bearing
                BCV’s trademarks.
              </li>
            </ul>

            <h5>12. TERM AND TERMINATION.</h5>
            <ul className='list-unstyled liststyled mt-3'>
              <li>
                <b>12.1. Term.</b> The Agreement shall begin on the Effective
                Date and shall continue until the earlier of (a) its termination
                pursuant to the terms of the Agreement, or (b) the expiration or
                termination of all SOWs and SOs between the Parties (the
                “Term”).
              </li>

              <li>
                <b>12.2. Termination for Cause.</b>

                <ol className='list-unstyled liststyled ms-3'>
                  <li>
                    <b>a. By Customer.</b> Customer may terminate this Agreement
                    for cause, upon notice to BCV if: (i) a receiver or
                    administrator is appointed for BCV or its property; (ii) BCV
                    makes a general assignment for the benefit of its creditors;
                    (iii) BCV commences, or has commenced against it,
                    proceedings under any bankruptcy, insolvency or debtor’s
                    relief law which are not dismissed within 60 days; (iv) BCV
                    is liquidated or dissolved; (v) BCV ceases to do business or
                    otherwise terminates its business operations or (vi) BCV
                    materially breaches this Agreement and such breach continues
                    unremedied for 30 days after receipt of notice from
                    Customer. For clarity and subject to Section 14.5 (Force
                    Majeure), a Services Outage as defined in the applicable SLA
                    is not grounds to terminate this Agreement for cause;
                    Customer's sole remedy for a Services Outage is set forth in
                    the applicable SLA.
                  </li>

                  <li>
                    <b>b. By BCV.</b> BCV may terminate this Agreement for cause
                    and/or suspend Services upon notice to Customer if (i) a
                    receiver or administrator is appointed for Customer or its
                    property; (ii) Customer makes a general assignment for the
                    benefit of its creditors; (iii) Customer commences, or has
                    commenced against it, proceedings under any bankruptcy,
                    insolvency or debtor’s relief law which are not dismissed
                    within 60 days; (iv) Customer is liquidated or dissolved;
                    (v) Customer ceases to do business or otherwise terminates
                    its business operations; (vi) Customer fails to pay all
                    invoiced fees or charges within 15 days from the invoice
                    date, in accordance with this Agreement; (vii) Customer
                    breaches this Agreement and such breach continues unremedied
                    for 30 days after receipt of notice from BCV. BCV may
                    terminate, interrupt or suspend Service without prior notice
                    if necessary to (1) prevent or protect against fraud, (2)
                    protect BCV’s customers, personnel, facilities, equipment,
                    network or services, (3) prevent violation of Section 7
                    (Representations and Warranties) or infringement upon the
                    rights of others or (4) prevent potential material
                    liability.
                  </li>
                </ol>
              </li>

              <li>
                <b>12.3. Termination by BCV for Change of Control.</b> In the
                event of Customer undergoes a Change of Control, BCV may
                terminate the Agreement upon 30 days’ notice to Customer's
                successor/transferee; provided, that, BCV has determined in its
                sole discretion that Customer’s successor/transferee is either
                (a) unable to meet BCV’s standards for creditworthiness, (b) is
                unable to assume and fulfill Customer’s obligations under the
                Agreement, (c) otherwise presents an undue financial or credit
                risk, or (d) is a competitor of BCV.
              </li>

              <li>
                <b>12.4. Effect of Termination.</b>
                <ol className='list-unstyled liststyled ms-3'>
                  <li>
                    <b>a.</b> Upon expiration or termination of the Agreement
                    and/or applicable SO, SOW or Service Supplement for any
                    reason, (i) all Customer rights to access or use Services
                    and any other BCV Intellectual Property Rights shall
                    terminate and BCV shall cease providing same, (ii) Customer
                    shall pay to BCV all fees or charges accrued but unpaid,
                    (iii) all liabilities accrued before the date of expiration
                    or termination shall survive and (iv) as directed by each
                    Disclosing Party, each Receiving Party shall return or
                    destroy, and certify in writing to the Disclosing Party such
                    destruction of, all copies of Disclosing Party’s
                    Confidential Information.
                  </li>

                  <li>
                    <b>b.</b> If Customer terminates the Agreement, a SO, SOW or
                    Service Supplement early without cause or if BCV terminates
                    the Agreement, a SO, SOW or Service Supplement for cause,
                    Customer shall be invoiced and pay to BCV an early
                    termination charge in such amount as set forth in the
                    applicable SO, SOW or Service Supplement. Such early
                    termination charge is agreed by the Parties to be liquidated
                    damages and is not a penalty.
                  </li>
                </ol>
              </li>
            </ul>

            <h5>13. PUBLICITY.</h5>
            <div className='mt-3'>
              During the Term, except as otherwise set forth herein, the Parties
              may not issue press releases or other public communications
              regarding the Parties’ relationship created by this Agreement or
              the Services without express prior consent. Customer shall not use
              BCV’s name, logo, trademarks and/or service marks, trade names,
              trade dress or other proprietary identifying symbols or otherwise
              identify or refer to BCV, except as specifically permitted under
              this Agreement or otherwise with BCV’s express prior consent.
              Customer grants BCV permission to use Customer’s logo and/or name
              on the BCV website, in BCV sales presentations, for marketing
              purposes and promotional materials, and to identify Customer as a
              customer of the Services in response to requests for information
              and responses to proposals. All other uses of Customer’s name,
              logo, trademarks and/or service marks, trade names, trade dress or
              other proprietary identifying symbols shall be subject to
              Customer’s consent, which shall not be unreasonably withheld.
            </div>

            <h5>14. MISCELLANEOUS.</h5>
            <ul className='list-unstyled liststyled mt-3'>
              <li>
                <b>14.1. Non-Solicitation.</b> During and for two year after the
                Term, Customer shall not, and shall ensure that its Affiliates
                do not, directly or indirectly solicit for employment any person
                employed by BCV to provide Services. Notwithstanding the
                foregoing, employment that is initiated by indirect solicitation
                (such as general newspaper advertisements or general online job
                postings not targeted at BCV's employees) shall not be
                considered a recruitment or solicitation hereunder.
              </li>

              <li>
                <b>14.2. No Third-Party Beneficiaries.</b> BCV has been
                authorized by its Partners as their authorized reseller/service
                provider of certain Platform Services (Platform Services) and
                that such Partner(s) shall also act as a beneficiary under the
                present agreement. There are no third-Party beneficiaries to
                this Agreement, including any insurance providers or Resale
                Customers.
              </li>

              <li>
                <b>14.3. Notices.</b> Any notices to be given hereunder to any
                other Party, including any notice of a change of address, shall
                be in writing and shall be deemed validly given if (a) delivered
                personally, (b) sent by overnight or second day express delivery
                service, (c) sent by registered or certified mail, postage
                prepaid, return receipt requested, or (d) sent by confirmed
                facsimile, as follows:
                <div className='mt-3'>
                  <p>If to BCV:</p>
                  <p>
                    BCV Tech India Pvt Ltd
                    <br />
                    Second Floor, Sai Hira, Mundhwa,
                    <br />
                    Pune, Maharashtra 411036, India
                    <br />
                    Attention:---------------------
                  </p>
                  <p>
                    If to Customer:
                    <br />
                    -----------------------------------
                    <br />
                    -----------------------------------
                    <br />
                    -----------------------------------
                    <br />
                    Attention:----------------------
                  </p>
                </div>
                <div className='mt-3'>
                  Either Party may change its contact information upon notice to
                  the other Party. For clarity, if a notice is not received
                  because the receiving Party has failed to notify the other
                  Party per the preceding sentence or because receipt is
                  refused, such notice nonetheless shall be deemed to have been
                  conclusively made seven days after delivery was reasonably
                  initiated.
                </div>
              </li>
              <li>
                <b>14.4. Force Majeure.</b> Neither Party shall be liable by
                reason of any failure or delay in the performance of its
                obligations herein due to acts of God, civil disorders, acts of
                terrorism, rebellion, fires, explosions, accidents, floods,
                vandalism, sabotage, unavailability of equipment, software or
                parts from vendors, work stoppages or other labor activity,
                labor conditions, shortages, fire, flood, storm, earthquake or
                other natural disaster, explosion, embargoes, strikes, labor
                disputes, riots, insurrection, war or unrest, military action,
                governmental restrictions or action, terrorism or threat of
                terrorism, computer viruses or worms, computer sabotage, ‘Denial
                of Service’ attacks, DNS spoofing attacks or other malicious
                online attacks (provided, that the Party claiming such cause has
                taken commercially reasonable steps to prevent such attacks) or
                other cause beyond such Party’s reasonable control (each, a
                "Force Majeure Event"). Customer’s obligation to pay for
                Services or products provided by BCV before a Force Majeure
                Event may be delayed during, but shall not be excused by, the
                Force Majeure Event. BCV may limit use of Services due to a
                Force Majeure Event. A Party whose performance is affected by a
                Force Majeure Event shall promptly provide notice with relevant
                details to the other Party and the obligations of the Party
                giving such notice shall be suspended to the extent caused by
                such Force Majeure Event for as long as the Force Majeure Event
                continues; and the time for performance of the affected
                obligation shall be extended by the delay caused by the Force
                Majeure Event. If the affected Party is prevented by the Force
                Majeure Event from performing its obligations with regard to a
                Service for 30 days, then it may in its sole discretion
                immediately terminate the affected Service by giving notice of
                termination to the other Party; provided, that, in the case of
                termination by Customer, Customer first provides BCV a
                reasonable opportunity to replace the affected Service with a
                comparable Service. Upon such termination, BCV is entitled to
                payment of all accrued but unpaid fees or charges incurred
                through the date of such termination. The Parties shall
                otherwise bear their own costs and BCV shall be under no further
                liability to perform the Services affected by the Force Majeure
                Event.
              </li>
              <li>
                <b>14.5. Choice of Law, Forum.</b> This Agreement shall be
                construed and enforced in accordance with the laws of India,
                without regard to conflict of laws principles. Each Party agrees
                that any action, suit or other proceeding arising from or based
                upon this Agreement (each, a "Dispute") will be brought and
                maintained only in a court of competent jurisdiction located in
                Pune, India. Each Party consents to the jurisdiction of such
                courts and waives any right to object to such jurisdiction. The
                prevailing Party in any Dispute shall be entitled to recovery of
                its reasonable attorneys' fees and costs. The Parties agree that
                the United Nations Convention on Contracts for the International
                Sale of Goods shall not apply to this Agreement.
              </li>
              <li>
                <b>14.6. Relationship of Parties.</b> The Parties are
                independent contractors and agree that this Agreement does not
                establish a partnership, association, joint venture or agency
                relationship or other co-operative entity between the Parties.
              </li>
              <li>
                <b>14.7. Waiver, Amendments.</b> No failure or delay by either
                Party to exercise or enforce any right herein shall operate as a
                waiver of any such right. This Agreement may be amended only by
                a writing signed by an authorized representative of BCV and an
                authorized representative of Customer.
              </li>
              <li>
                <b>14.8. Severability.</b> If any provision of this Agreement is
                held by a court of competent jurisdiction to be unenforceable or
                contrary to law, such holding shall not render the Agreement
                unenforceable or contrary to law as a whole, and, in such event,
                such provision shall be changed and interpreted so as to best
                accomplish the objectives of such provisions within the limits
                of applicable law.
              </li>
              <li>
                <b>14.9. Assignment.</b> Neither Party may assign this Agreement
                without the other Party’s express prior consent except that BCV
                may freely assign its rights and obligations under this
                Agreement, in whole or in part, (a) to a parent or Affiliate or
                (b) in connection with a Change of Control. This Agreement shall
                be binding upon and inure to the benefit of all permitted
                successors and assigns. Any assignment in contravention of this
                Section 14.10 is null and void.
              </li>
              <li>
                <b>14.10. Survival.</b> The Parties’ rights and obligations set
                forth in Sections 3.2(c) (Invoice Disputes), 4 (Intellectual
                Property), 6 (Confidentiality), 8 (Disclaimer), 9 (Limitation of
                Liability), 10 (Indemnification) and 14 (Miscellaneous) shall
                survive termination or expiration of this Agreement.
              </li>
              <li>
                <b>14.11. Entire Agreement.</b> This Agreement, the AUP(s), the
                Service Supplements (including any SLAs), the Exhibit(s), and
                all SOs, SOWs or Addenda between the Parties are incorporated
                herein by reference, constitute the entire agreement between the
                Parties with respect to its subject matter and supersede all
                other prior or contemporaneous representations, understandings
                or agreements; and there are no other representations,
                understandings or agreements between the Parties relative to
                such subject matter. Except as otherwise expressly stated
                herein, no amendment to this Agreement is valid unless in
                writing and signed by both Parties.
              </li>
              <li>
                <b>14.12. Remedies.</b> Unless otherwise provided for in this
                Agreement, to the extent permitted by applicable law, the
                Parties’ rights and remedies provided herein are cumulative and
                in addition to any other rights and remedies at law or equity.
              </li>
              <li>
                <b>14.13. Construction.</b> Descriptive headings in this
                Agreement are for convenience only and shall not affect the
                construction of this Agreement. The terms “include,” “including”
                or “e.g.,” mean “include, without limitation”. The term “day”
                means calendar day unless otherwise indicated. Terms with
                well-known technical or industry meanings are so construed. Each
                Party and its counsel have fully reviewed and contributed to
                this Agreement. Any rule of construction that ambiguities are
                resolved against the drafting Party shall not apply in
                interpreting this Agreement.
              </li>
            </ul>
          </div>

          <h4 className='text-center mt-5'>Exhibit A</h4>
          <h5 className='text-center mt-3'>Service Supplement</h5>

          <ul className='liststyled list-roman mt-3'>
            <li>
              <b>Introduction:</b> This Service Supplement (SOW) outlines the
              scope of services, deliverables, timelines, and terms of
              engagement for providing integrated Carbon Accounting and ESG
              (Environmental, Social, and Governance) services to Customer.
            </li>
            <li>
              <b>Objectives:</b> The primary objective of this engagement is to
              support Customer in enhancing its sustainability performance by
              integrating ESG considerations and carbon accounting into its
              business practices. Specific objectives include:
              <ol className='liststyel-decimal'>
                <li>
                  Develop a comprehensive understanding of the organization's
                  environmental impact, social performance, and governance
                  practices through ESG assessments.
                </li>
                <li>
                  Quantify and manage the organization's carbon footprint
                  through accurate carbon accounting and emission reduction
                  strategies.
                </li>
                <li>
                  Identify opportunities for improving ESG performance, reducing
                  carbon emissions, and enhancing long-term value creation.
                </li>
                <li>
                  Strengthen stakeholder engagement and communication on ESG
                  issues, climate initiatives, and sustainability goals.
                </li>
              </ol>
            </li>
            <li>
              <b>Scope of Services:</b> The scope of carbon accounting and ESG
              services to be provided includes, but is not limited to, the
              following:
              <ol className='liststyel-decimal'>
                <li>
                  Conduct an initial scoping meeting to define project
                  objectives, timelines, and key stakeholders.
                </li>
                <li>
                  Collaborate with Customer to collect relevant data on ESG
                  metrics, energy consumption, GHG emissions, waste generation,
                  and other environmental, social, and governance factors.
                </li>
                <li>
                  Develop a comprehensive ESG assessment framework, including
                  materiality analysis, stakeholder mapping, and performance
                  benchmarking.
                </li>
                <li>
                  Conduct a carbon accounting assessment to quantify GHG
                  emissions for Scope 1 (direct emissions), Scope 2 (indirect
                  emissions from purchased electricity), and, if applicable,
                  Scope 3 (indirect emissions from sources such as supply chain,
                  business travel, and waste disposal).
                </li>
                <li>
                  Prepare ESG reports and disclosures, including key performance
                  indicators (KPIs), materiality assessments, and stakeholder
                  engagement summaries.
                </li>
                <li>
                  Develop a carbon reduction strategy, including emission
                  reduction targets, action plans, and implementation roadmap
                  aligned with global best practices and stakeholder
                  expectations.
                </li>
                <li>
                  Facilitate stakeholder engagement and communication on ESG
                  performance, climate initiatives, and sustainability goals
                  through presentations, workshops, and outreach activities.
                </li>
                <li>
                  Offer training and capacity building sessions to empower
                  Customer staff with the knowledge and skills needed to
                  implement and sustain ESG and carbon accounting initiatives.
                </li>
              </ol>
            </li>
            <li>
              <b>Deliverables:</b> The following deliverables will be provided
              as part of this engagement:
              <ol className='liststyel-decimal'>
                <li>
                  ESG assessment report, including materiality analysis,
                  performance benchmarks, and stakeholder engagement findings.
                </li>
                <li>
                  Carbon accounting report, including GHG emissions inventory,
                  methodology descriptions, and emission reduction
                  recommendations.
                </li>
                <li>
                  ESG reports and disclosures, prepared in accordance with
                  recognized reporting frameworks and standards (e.g., GRI,
                  SASB, TCFD).
                </li>
                <li>
                  Carbon reduction strategy document, including emission
                  reduction targets, action plans, and implementation roadmap.
                </li>
                <li>
                  Stakeholder engagement materials, such as presentations,
                  communication plans, and outreach materials.
                </li>
                <li>
                  Training materials and resources for capacity building and
                  knowledge transfer.
                </li>
              </ol>
            </li>
            <li>
              <b>Roles and Responsibilities:</b>
              <ol className='liststyel-decimal'>
                <li>
                  Customer will provide access to relevant data, information,
                  and personnel necessary for conducting the ESG and carbon
                  accounting services.
                </li>
                <li>
                  The service provider will conduct the necessary analyses,
                  assessments, and reporting tasks in accordance with
                  agreed-upon methodologies and standards.
                </li>
                <li>
                  Both parties will collaborate closely throughout the
                  engagement to ensure timely delivery of deliverables and
                  achievement of project objectives.
                </li>
              </ol>
            </li>
            <li>
              <b>Terms and Conditions:</b>
              <p className='mt-3'>
                The fees for the ESG and carbon accounting services and the
                scope of work, estimated effort, and agreed-upon pricing will be
                outlined in a Service Order (SO).
              </p>
              <p>
                Any changes to the scope of work or project timeline will be
                subject to mutual agreement between Customer and the service
                provider.
              </p>
            </li>
          </ul>
        </Container>
      </section>

      <Footer />
    </div>
  );
}

export default TermsConditions;
