import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import Header from '../../shared/header/Header';
import Footer from '../../shared/footer/Footer';
import {
  BlogApiService,
  CategoryApiService,
  SubCategoryApiService
} from '../../services/apiService';
import { COVER_IMAGE_BASE_URL } from '../../services/constants';

function BlogDetails() {
  const reactLocation = useLocation();
  const locationState = reactLocation.state;

  const params = useParams();
  const slug = params.slug;

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [article, setArticle] = useState({});
  const [suggestedArticles, setSuggestedArticles] = useState([]);

  const getArticle = () => {
    BlogApiService.getBlog(slug)
      .then((response) => {
        if (response.data.success === true) {
          setArticle(response.data.blog);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getCategories = () => {
    CategoryApiService.getCategories()
      .then((response) => {
        if (response.data.success === true) {
          setCategories(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSubCategories = () => {
    SubCategoryApiService.getSubCategories()
      .then((response) => {
        if (response.data.success === true) {
          setSubCategories(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSuggestedArticles = () => {
    BlogApiService.getSuggestedArticles(slug)
      .then((response) => {
        if (response.data.success === true) {
          setSuggestedArticles(
            response.data.data.filter((item) => {
              return item?.live;
            })
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getArticle();
    getCategories();
    getSubCategories();
    getSuggestedArticles();
  }, [slug]);

  const getFormattedDate = (date) => {
    if (!date) {
      return '';
    }
    const d = new Date(date);
    return d.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className='bloglist-container planeHeader'>
      <Header />

      <section className='section-padd mt-4'>
        <Container>
          <Row>
            <Col md={5}>
              <div className='imgblogdetails'>
                <img
                  src={COVER_IMAGE_BASE_URL + article?.coverimg}
                  className='rounded-4'
                  alt='blog details'
                />
              </div>
            </Col>
            <Col md={7} className='mt-4 mt-md-0'>
              <div className='blog-details-head mb-3'>
                <h2>{article?.title}</h2>
                <p>{article?.description}</p>
              </div>

              <Badge bg='secondary' as={Link} to={`/articles`}>
                {
                  subCategories.find(
                    (sc) => sc.id === parseInt(article.subcategory)
                  )?.name
                }
              </Badge>
              <div className='subtangs'>
                {article?.keywords &&
                  article?.keywords.map((tag, index) => (
                    <Badge bg='light' key={index}>
                      {tag}
                    </Badge>
                  ))}
                {/* <Badge bg="light">Reporting</Badge> */}
              </div>

              <div className='blog-dateowner mt-5'>
                <p className='mb-1'>{getFormattedDate(article?.createdAt)}</p>
                <div className='blogowner'>
                  <div className='bownerimg'>
                    <img src={'/assets/img/user.svg'} alt='user' />
                  </div>
                  <span>{article?.author}</span>
                </div>
              </div>
            </Col>
          </Row>

          <div
            className='blogdetails-content mt-5'
            dangerouslySetInnerHTML={{ __html: article?.content }}
          />
        </Container>
      </section>

      <section className='relativeArticle bg-light py-5'>
        <Container>
          <h5>READ MORE</h5>
          {suggestedArticles.length > 0 && (
            <OwlCarousel
              items={4}
              className='recources-slider'
              nav
              dots
              margin={20}
              responsive={{ 1: { items: 1, dots: false }, 991: { items: 4 } }}
            >
              {suggestedArticles.map((articl) => (
                <div key={articl.id} className='mt-4'>
                  <Row>
                    <Col xs={12}>
                      <div className='rm-bloglist-img'>
                        <Link to={`/article/${articl.slug}`}>
                          <img
                            src={COVER_IMAGE_BASE_URL + articl.coverimg}
                            alt=''
                          />
                        </Link>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className='rm-blog-txt mt-3'>
                        <Badge bg='secondary' as={Link} to={`/`}>
                          {
                            subCategories.find(
                              (sc) => sc.id === parseInt(articl.subcategory)
                            )?.name
                          }
                        </Badge>
                        <h5 className='mt-2'>
                          <Link to={`/article/${articl.slug}`}>
                            {articl.title}
                          </Link>
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </OwlCarousel>
          )}
        </Container>
      </section>

      <section className='section-padd cta-section'>
        <Container>
          <div className='text-center cta-text wow fadeInUp'>
            <h2 className='mb-4 mb-lg-5'>
              Begin your decarbonization journey today
            </h2>
            <p>
              Our sustainability experts will advise you on an effective
              strategy for your business.
            </p>
            <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>
              Book A Demo
            </a>
          </div>
        </Container>
      </section>

      <Footer />
    </div>
  );
}

export default BlogDetails;
