import axios from 'axios';
import { API_BASE_URL } from './constants';
// const API_BASE_URL = "https://api.antahsukham.com/api/v1";
// const API_BASE_URL = "http://localhost:8080/api/v1";
export const DemoBookingApiService = {
  route: 'demoBooking',

  addDemoBooking(body) {
    return axios.post(`${API_BASE_URL}/${this.route}/addDemoBooking`, body);
  }
};

export const BlogApiService = {
  route: 'blog',

  getBlogList() {
    return axios.get(`${API_BASE_URL}/${this.route}/getBlogsPublic`);
  },

  getBlog(slug) {
    return axios.get(`${API_BASE_URL}/${this.route}/getBlog/${slug}`);
  },
  getSuggestedArticles(slug) {
    return axios.get(`${API_BASE_URL}/${this.route}/getSuggestedBlogs/${slug}`);
  },
  getResourceBlog() {
    return axios.get(`${API_BASE_URL}/${this.route}/homeBlog`);
  },
  getCaseStudyBlog() {
    return axios.get(`${API_BASE_URL}/${this.route}/caseStudy`);
  },
  getCaseStudyTopThree() {
    return axios.get(`${API_BASE_URL}/${this.route}/caseStudyTopThree`);
  }
};

export const CategoryApiService = {
  route: 'category',

  getCategories() {
    return axios.get(`${API_BASE_URL}/${this.route}/getCategories`);
  }
};

export const SubCategoryApiService = {
  route: 'subCategory',

  getSubCategories() {
    return axios.get(`${API_BASE_URL}/${this.route}/getSubCategories`);
  }
};

export const BrandLogoApiService = {
  route: 'brandLogo',

  getBrandLogos() {
    return axios.get(`${API_BASE_URL}/${this.route}/getBrandLogos`);
  }
};

export const GlossaryApiService = {
  route: 'glossary',

  getGlossaries() {
    return axios.get(`${API_BASE_URL}/${this.route}/getGlossaries`);
  }
};
