import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { GoArrowRight } from 'react-icons/go';
import { useLocation } from 'react-router-dom';
import Header from '../../../shared/header/Header';
import Footer from '../../../shared/footer/Footer';
import './ProductDetails.scss';
import {
  BlogApiService,
  SubCategoryApiService
} from '../../../services/apiService';
import { COVER_IMAGE_BASE_URL } from '../../../services/constants';

function ReportData() {
  let location = useLocation();

  const [casestudy, setCaseStudy] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const getCaseStudyBlog = async () => {
    BlogApiService.getCaseStudyTopThree()
      .then(async (res) => {
        if (res.data) {
          setCaseStudy(await res.data.blog);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSubCategories = () => {
    SubCategoryApiService.getSubCategories()
      .then((response) => {
        if (response.data.success === true) {
          setSubCategories(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getSubCategories();
    getCaseStudyBlog();
  }, []);
  return (
    <div className='products-container ll-logo-display'>
      <Header />

      <div className='inner-hero'>
        <Container>
          <div className='about-hero-txt'>
            <div className='p-details-nav'>
              <Row className='justify-content-center'>
                <Col xs={4} md={3} lg={2}>
                  <a
                    className={`btn-primary ${
                      location.pathname === '/collect-data' ? 'active' : ''
                    } btn rounded-pill`}
                    href='/collect-data'
                  >
                    01 Collect
                  </a>
                </Col>
                <Col xs={4} md={3} lg={2}>
                  <a
                    href='/analyze-data'
                    className={`btn-primary ${
                      location.pathname === '/analyze-data' ? 'active' : ''
                    } btn rounded-pill`}
                  >
                    02 Analyze
                  </a>
                </Col>
                <Col xs={4} md={3} lg={2}>
                  <a
                    href='/report-data'
                    className={`btn-primary ${
                      location.pathname === '/report-data' ? 'active' : ''
                    } btn rounded-pill`}
                  >
                    03 Report
                  </a>
                </Col>
                <Col xs={4} md={3} lg={2}>
                  <a
                    href='/build-strategy'
                    className={`btn-primary ${
                      location.pathname === '/build-strategy' ? 'active' : ''
                    } btn rounded-pill`}
                  >
                    04 Build
                  </a>
                </Col>
                <Col xs={4} md={3} lg={2}>
                  <a
                    href='/reduce-emissions'
                    className={`btn-primary ${
                      location.pathname === '/reduce-emissions' ? 'active' : ''
                    } btn rounded-pill`}
                  >
                    05 Reduce
                  </a>
                </Col>
                <Col xs={4} md={3} lg={2}>
                  <a
                    href='/improve-strategy'
                    className={`btn-primary ${
                      location.pathname === '/improve-strategy' ? 'active' : ''
                    } btn rounded-pill`}
                  >
                    06 Improve
                  </a>
                </Col>
              </Row>
            </div>
            <Row className='justify-content-center text-center'>
              <Col lg={7} className='wow fadeInUp mt-5 mt-lg-0'>
                <h1 className=''>
                  Streamline Your ESG Reporting with AI-Driven Automation
                </h1>
                <p className='mt-4'>
                  Easily create sustainability reports with frameworks like
                  SASB, GRI, BRSR, and TCFD. Our AI assists you in automating
                  your reporting process and simplifies team collaboration.
                </p>
                <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>
                  Start reporting on ESG
                </a>
              </Col>
              <Col lg='11' className='text-center pt-5'>
                <img
                  src={'/assets/img/products/6.webp'}
                  className='img-fluid details-img rounded-4'
                  alt='Products banner'
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <section className='section-padd products-details-sec trans-bg'>
        <Container>
          <Row>
            <Col lg={5} className='wow fadeInRight order-lg-last'>
              <img
                src={'/assets/img/products/report-data.svg'}
                className='img-fluid'
                alt='Collect Data'
              />
            </Col>
            <Col lg={7} className='align-self-center mt-5 mt-lg-0 wow fadeInUp'>
              <h6 className='mb-3'>Introduction</h6>
              <h2>
                ESG (Environmental, Social, and Governance) reporting is no
                longer optional—it is a necessity for compliance and business
                success.
              </h2>
              <p className='mt-4'>
                Our platform connects carbon accounting, ESG, and AI to help you
                create comprehensive and accurate sustainability reports. With
                user-friendly dashboards and AI assistance, you can fill reports
                faster, collaborate across teams, and design custom disclosures
                with our built-in content editor.
              </p>
            </Col>
            {/* <Col lg={1}></Col> */}
          </Row>
        </Container>
      </section>

      <section className='section-padd-bottom savetime-section products-details-sec'>
        <Container>
          <Row className='wow fadeInUp'>
            <Col md={9}>
              <h6 className='mb-4'>Our Solution</h6>
              <h2>
                Our reporting module offers powerful tools to automate and
                simplify ESG reporting:
              </h2>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col md={4} className='wow fadeInUp'>
              <Card className='border-0'>
                <Card.Body className='p-0'>
                  <Card.Text>
                    <div className='card-no-n'>01</div>
                    <strong>Automated Annual Reports:</strong> Create reports
                    outlining your scope 1, 2, and 3 emissions, complete with
                    detailed graphs that show your progress toward net-zero
                    goals.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className='mt-4 mt-md-0 wow fadeInDown'>
              <Card className='border-0'>
                <Card.Body className='p-0'>
                  <Card.Text>
                    <div className='card-no-n'>02</div>
                    <strong>Framework Compliance:</strong> Export non-financial
                    reports adhering to major frameworks like SASB, GRI, BRSR,
                    and TCFD, ensuring you meet international standards.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className='mt-4 mt-md-0 wow fadeInUp'>
              <Card className='border-0'>
                <Card.Body className='p-0'>
                  <Card.Text>
                    <div className='card-no-n'>03</div>
                    <strong>
                      User-Friendly Dashboards and AI Assistance:
                    </strong>{' '}
                    Our platform's dashboards are designed for ease of use,
                    helping you fill data quickly. AI assists you in creating
                    disclosures faster based on centralized sustainability data.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className='mt-4 mt-md-0 wow fadeInUp'>
              <Card className='border-0'>
                <Card.Body className='p-0'>
                  <Card.Text>
                    <div className='card-no-n'>04</div>
                    <strong>
                      Cross-Team Collaboration and Content Editor:
                    </strong>{' '}
                    Collaborate seamlessly across teams and use our inbuilt
                    content editor to design and build reports to meet your
                    specific business needs.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='section-padd-bottom opportunity-section'>
        <Container>
          <div className='details-slide'>
            <Row>
              <Col lg={6}>
                <div className='oppor-left'>
                  <h2>Why Is Accurate ESG Reporting Important?</h2>
                  <p className='mt-4'>
                    Accurate ESG reporting is vital for several reasons:
                  </p>
                  <a href='/book-a-demo' className='btn btn-primary px-4 mt-5'>
                    Start reporting on ESG
                  </a>
                </div>
              </Col>
              <Col lg={6}>
                <div className='opportunity-box wow fadeInUp mt-0'>
                  <Row className='mt-2'>
                    <Col sm={12} className='align-self-center'>
                      <div className='no-icon-c'>
                        <img
                          src={'/assets/img/icons/reap-benefits-climate.svg'}
                          className='img-fluid'
                          alt='Reap the Benefits'
                        />
                      </div>
                      <h5 className='pb-3'>
                        Reap the Benefits of Climate Disclosure
                      </h5>
                      <div className='text-oppor'>
                        Disclosing climate risks is crucial as climate change's
                        impact becomes more evident. Proper disclosure can
                        prevent poor investment decisions and asset losses.
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className='opportunity-box wow fadeInUp'>
                  <Row className='mt-2'>
                    <Col sm={12} className='align-self-center'>
                      <div className='no-icon-c'>
                        <img
                          src={
                            '/assets/img/icons/protect-improve-your-company.svg'
                          }
                          className='img-fluid'
                          alt='Protect and Improve Your Company'
                        />
                      </div>
                      <h5 className='pb-3'>
                        Protect and Improve Your Company's Reputation
                      </h5>
                      <div className='text-oppor'>
                        ESG reporting enhances your company's legitimacy and
                        reputation. It builds trust through transparency and
                        addresses the growing environmental concerns among
                        stakeholders and the public.
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className='opportunity-box wow fadeInUp'>
                  <Row className='mt-2'>
                    <Col sm={12} className='align-self-center'>
                      <div className='no-icon-c'>
                        <img
                          src={
                            '/assets/img/icons/boost-competitive-advantage .svg'
                          }
                          className='img-fluid'
                          alt='Boost Your Competitive Advantage'
                        />
                      </div>
                      <h5 className='pb-3'>Boost Your Competitive Advantage</h5>
                      <div className='text-oppor'>
                        ESG reporting can give you an edge in stock market
                        performance, access to capital, and winning tenders.
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className='opportunity-box wow fadeInUp'>
                  <Row className='mt-2'>
                    <Col sm={12} className='align-self-center'>
                      <div className='no-icon-c'>
                        <img
                          src={'/assets/img/icons/go-ahead-regulations.svg'}
                          className='img-fluid'
                          alt='Get Ahead of Regulations'
                        />
                      </div>
                      <h5 className='pb-3'>Get Ahead of Regulations</h5>
                      <div className='text-oppor'>
                        As ESG disclosure becomes mandatory, complying with
                        regulations helps you avoid hefty fines and reputational
                        damage. Understanding these requirements ensures you are
                        prepared for future changes.
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className='section-padd cta-section'>
        <Container>
          <div className='text-center cta-text wow fadeInUp'>
            <h2 className='mb-4 mb-lg-5'>
              Begin your decarbonization journey today
            </h2>
            <p>
              Our sustainability experts will advise you on an effective
              strategy for your business.
            </p>
            <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>
              Book A Demo
            </a>
          </div>
        </Container>
      </section>

      <section className='section-padd exploreStory'>
        <Container>
          <h6 className='mb-4'>CASE STUDIES</h6>
          {casestudy.length > 0 && (
            <Row>
              {casestudy.map((blog) => (
                <Col lg={4} className='mt-5 wow fadeInUp'>
                  <div className='casestudy-box'>
                    <div className='casestudy-image'>
                      <img
                        src={COVER_IMAGE_BASE_URL + blog?.coverimg}
                        className='img-fluid'
                        alt='case Study'
                      />
                    </div>
                    <div className='casestudy-text'>
                      <h6>
                        {
                          subCategories.find(
                            (sc) => sc.id === parseInt(blog.subcategory)
                          )?.name
                        }
                      </h6>
                      <h5>{blog.title}</h5>
                      <a
                        href={`/article/${blog.slug}`}
                        className='btn btn-outline-primary px-4 mt-2 mt-lg-4'
                      >
                        Read Case Study <GoArrowRight />
                      </a>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </section>

      <section className='footerpro-links'>
        <Container>
          <div className='p-details-nav'>
            <Row className='justify-content-center'>
              <Col xs={4} md={3} lg={2}>
                <a
                  className={`btn-primary ${
                    location.pathname === '/collect-data' ? 'active' : ''
                  } btn rounded-pill`}
                  href='/collect-data'
                >
                  01 Collect
                </a>
              </Col>
              <Col xs={4} md={3} lg={2}>
                <a
                  href='/analyze-data'
                  className={`btn-primary ${
                    location.pathname === '/analyze-data' ? 'active' : ''
                  } btn rounded-pill`}
                >
                  02 Analyze
                </a>
              </Col>
              <Col xs={4} md={3} lg={2}>
                <a
                  href='/report-data'
                  className={`btn-primary ${
                    location.pathname === '/report-data' ? 'active' : ''
                  } btn rounded-pill`}
                >
                  03 Report
                </a>
              </Col>
              <Col xs={4} md={3} lg={2}>
                <a
                  href='/build-strategy'
                  className={`btn-primary ${
                    location.pathname === '/build-strategy' ? 'active' : ''
                  } btn rounded-pill`}
                >
                  04 Build
                </a>
              </Col>
              <Col xs={4} md={3} lg={2}>
                <a
                  href='/reduce-emissions'
                  className={`btn-primary ${
                    location.pathname === '/reduce-emissions' ? 'active' : ''
                  } btn rounded-pill`}
                >
                  05 Reduce
                </a>
              </Col>
              <Col xs={4} md={3} lg={2}>
                <a
                  href='/improve-strategy'
                  className={`btn-primary ${
                    location.pathname === '/improve-strategy' ? 'active' : ''
                  } btn rounded-pill`}
                >
                  06 Improve
                </a>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <Footer />
    </div>
  );
}

export default ReportData;
