import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Badge, Card } from 'react-bootstrap';
import { IoCloseOutline, IoSearchOutline } from "react-icons/io5";
import Header from '../../shared/header/Header';
import Footer from '../../shared/footer/Footer';
import { COVER_IMAGE_BASE_URL } from '../../services/constants';
import { BlogApiService, CategoryApiService, SubCategoryApiService } from '../../services/apiService';

function BlogList() {

    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])

    const [allArticles, setAllArticles] = useState([])
    const [currentArticles, setCurrentArticles] = useState([])

    const [searchTerm, setSearchTerm] = useState('');

    const getArticles = () => {
        BlogApiService.getBlogList()
            .then(response => {
                if (response.data.success === true) {
                    setAllArticles(response.data.blogs)
                    setCurrentArticles(response.data.blogs)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    const getCategories = () => {
        CategoryApiService.getCategories()
            .then(response => {
                if (response.data.success === true) {
                    setCategories(response.data.data)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    const getSubCategories = () => {
        SubCategoryApiService.getSubCategories()
            .then(response => {
                if (response.data.success === true) {
                    setSubCategories(response.data.data)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    useEffect(() => {
        getArticles();
        getCategories();
        getSubCategories();
    }, [])

    function getRandomUniqueBlogs(arr) {
        let maxLen = arr.length < 3 ? arr.length : 3;
        const selectedElements = new Set();
        while (selectedElements.size < maxLen) {
            const randomIndex = Math.floor(Math.random() * arr.length);
            selectedElements.add(arr[randomIndex]);
        }
        return Array.from(selectedElements);
    }

    return (
        <div className='bloglist-container planeHeader'>
            <Header />

            <section className='section-padd-top'>
                <Container>
                    <div className='mt-4 categoryLists'>
                        <span>CATEGORIES</span>
                        <div className='categories'>
                            <Badge bg="secondary">Climate Science</Badge>
                            <Badge bg="secondary">Corporate Sustainability</Badge>
                            <Badge bg="secondary">Bharat Carbon News</Badge>
                            <Badge bg="secondary">Policy & Regulations</Badge>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <Row>
                            <Col md={7}>
                                <h2>All Bharat Carbon articles</h2>
                                <p>Explore the latest articles driving positive change in businesses, paving the way towards a sustainable future.</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className='section-padd-bottom mt-5'>
                <Container>
                    <Row className='blogs-search glossary-search'>
                        <Col md="4">
                            <h6>SEARCH ARTICLES</h6>
                            <Form>
                                <Form.Group className="mt-3 position-relative" controlId="searchType">
                                    <Form.Control
                                        type="text"
                                        placeholder="Type to search articles..."
                                        value={searchTerm}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            setSearchTerm(val);
                                            setCurrentArticles(allArticles.filter(article => article.title.toLowerCase().includes(val.toLowerCase())));
                                        }}
                                    />
                                    <div className='searchicon'><IoSearchOutline /></div>
                                </Form.Group>
                            </Form>
                            <div className='glossary-sorting mt-2'>
                                <div className='noofglossary'><span className='active-glo'>{currentArticles.length}</span> of <span className='total-glo'>{allArticles.length}</span> Articles shown</div>
                            </div>
                        </Col>
                        <Col md="4" className='ps-md-5'>
                            <div className='selectedFilter'>
                                <h6>SELECTED FILTER</h6>
                                {searchTerm.trim() !== '' && <Badge bg="secondary">{searchTerm.trim()}<IoCloseOutline onClick={() => {
                                    setSearchTerm('');
                                    setCurrentArticles(allArticles);
                                }} /></Badge>}
                            </div>
                        </Col>
                        <Col md="4" className='text-md-end'>
                            <span className='clearsearch' onClick={() => {
                                setSearchTerm('');
                                setCurrentArticles(allArticles);
                            }}>
                                Clear Filters <IoCloseOutline />
                            </span>
                        </Col>
                    </Row>

                    <div className='articleslist-con'>
                        <Row>
                            {currentArticles.map((article, index) => (
                                <Col md={4} key={article.id} className='mt-5'>
                                    <Card className='articleBox'>
                                        <div className='blogimg mb-3 rounded-4'>
                                            <Card.Img variant="top" src={COVER_IMAGE_BASE_URL + article.coverimg} />
                                        </div>
                                        <Card.Body>
                                            <Badge bg="secondary">{subCategories.find(sc => sc.id === parseInt(article?.subcategory))?.name}</Badge>
                                            <Card.Title as={Link} to={`/article/${article.slug}`} state={getRandomUniqueBlogs(allArticles.filter(art => art.slug != article.slug))}>{article.title}</Card.Title>
                                            {/* <Card.Text>
                                                {article.description}
                                            </Card.Text> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>

                </Container>
            </section>

            <section className='section-padd cta-section'>
                <Container>
                    <div className='text-center cta-text wow fadeInUp'>
                        <h2 className='mb-4 mb-lg-5'>Begin your decarbonization journey today</h2>
                        <p>Our sustainability experts will advise you on an effective strategy for your business.</p>
                        <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>Book A Demo</a>
                    </div>
                </Container>
            </section>
            <Footer />
        </div>
    )

}

export default BlogList