import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import Header from '../../shared/header/Header';
import Footer from '../../shared/footer/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import './About.scss';

function About() {
  return (
    <div className='About-container ll-logo-display'>
      <Header />

      <div className='inner-hero'>
        <Container>
          <div className='about-hero-txt'>
            <Row>
              <Col lg={4} className='order-lg-last wow fadeInRight'>
                <img
                  src={'/assets/img/about-hero.webp'}
                  className='about-image'
                  alt='about banner'
                />
              </Col>

              <Col
                lg={7}
                className='align-self-center wow fadeInUp mt-5 mt-lg-0'
              >
                <h6 className='mb-4'>Our Mission</h6>
                <h1>
                  To drive the sustainable transition of the global economy.
                </h1>
                <p className='mt-4'>
                  Bharat Carbon aims to develop solutions that help companies,
                  cities, and governments reduce carbon emissions and accelerate
                  decarbonization.
                </p>
              </Col>
              <Col lg='1'></Col>
            </Row>
          </div>
        </Container>
      </div>

      <section className='section-padd ourstory'>
        <Container>
          <Row>
            <Col lg={8}>
              <h6 className='mb-4 wow fadeInUp'>Our Story in Numbers</h6>
              <h2 className='mb-4 wow fadeInUp'>
                Our Responsibility to the Environment
              </h2>
              <p className='wow fadeInUp'>
                Established in 2022, Bharat Carbon leads the way in
                sustainability solutions. As a cutting-edge SaaS platform, we
                specialize in corporate carbon accounting, decarbonization, and
                ESG reporting. But we're not just a software company—we're
                setting the standard for the future of sustainable business.
              </p>
            </Col>
          </Row>

          <Row className='mt-3'>
            <Col lg={4} className='mt-4 wow fadeInUp'>
              <Card className='border-0 rounded-0 story-block'>
                <Card.Body className='px-0'>
                  <div className='card-text'>
                    <h6>1 Billion</h6>
                    <h5>Tonnes of Carbon Reductions</h5>
                    <p>
                      Our vision is to measure and reduce 1 billion tonnes of
                      CO2e globally. Bharat Carbon is dedicated to guiding
                      businesses across different industries toward achieving
                      their net-zero targets.
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} className='mt-4 wow fadeInDown'>
              <Card className='border-0 rounded-0 story-block'>
                <Card.Body className='px-0'>
                  <div className='card-text'>
                    <h6>6 Industries</h6>
                    <h5>Across 3 Countries</h5>
                    <p>
                      We have tailored our platform to meet the unique needs of
                      six industries, focusing on environmental and
                      industry-specific research in three countries: India, USA
                      & Japan. This approach ensures that our solutions are
                      grounded in real-world applications and tailored to
                      specific industry requirements.
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} className='mt-4 wow fadeInUp'>
              <Card className='border-0 rounded-0 story-block'>
                <Card.Body className='px-0'>
                  <div className='card-text'>
                    <h6>2,000+</h6>
                    <h5>Database of ESG Companies</h5>
                    <p>
                      By integrating a database of over 2,000 ESG companies we
                      enhance the precision of our calculations, facilitate
                      robust peer benchmarking, and continuously optimize our AI
                      engine. This dataset ensures that our solutions are
                      informed, accurate, and tailored to drive true
                      sustainability outcomes for our clients.
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='section-padd-bottom team-members '>
        <Container>
          <div className='mb-5 wow fadeInUp'>
            <h6>Our Team</h6>
            <h2>Meet the team</h2>
          </div>
          <OwlCarousel
            items={5}
            className='clients-slider team-members wow fadeInUp'
            nav
            dots
            margin={20}
            responsive={{
              1: { items: 2, dots: false },
              768: { items: 3 },
              1024: { items: 5 }
            }}
          >
            <div className='team-box'>
              <div className='team-member'>
                <img src={'/assets/img/team/Tanish.png'} alt='' />
              </div>
              <div className='team-info'>
                <h6>
                  Tanish
                  <br />
                  Shewani
                </h6>
                <p className='mb-0'>CEO / Founder</p>
              </div>
            </div>

            <div className='team-box'>
              <div className='team-member'>
                <img src={'/assets/img/team/Anis.png'} alt='' />
              </div>
              <div className='team-info'>
                <h6>
                  Anis
                  <br />
                  Nathani
                </h6>
                <p className='mb-0'>COO</p>
              </div>
            </div>

            <div className='team-box'>
              <div className='team-member'>
                <img src={'/assets/img/team/Kaushik.png'} alt='' />
              </div>
              <div className='team-info'>
                <h6>
                  Kaushik
                  <br />
                  Badiyani
                </h6>
                <p className='mb-0'>CTO</p>
              </div>
            </div>

            <div className='team-box'>
              <div className='team-member'>
                <img src={'/assets/img/team/Kishore.png'} alt='' />
              </div>
              <div className='team-info'>
                <h6>
                  Kishore
                  <br />
                  Dalvi
                </h6>
                <p className='mb-0'>CDO</p>
              </div>
            </div>

            <div className='team-box'>
              <div className='team-member'>
                <img src={'/assets/img/team/Aniket.png'} alt='' />
              </div>
              <div className='team-info'>
                <h6>
                  Aniket
                  <br />
                  Gundecha
                </h6>
                <p className='mb-0'>Chief Architect</p>
              </div>
            </div>

            <div className='team-box'>
              <div className='team-member'>
                <img src={'/assets/img/team/Akshay-j.png'} alt='' />
              </div>
              <div className='team-info'>
                <h6>
                  Akshay
                  <br />
                  Juyal
                </h6>
                <p className='mb-0'>AI Architect</p>
              </div>
            </div>

            {/* <div className='team-box'>
              <div className='team-member'>
                <img src={'/assets/img/team/Sachin.png'} alt='Sachin Arora' />
              </div>
              <div className='team-info'>
                <h6>
                  Sachin
                  <br />
                  Arora
                </h6>
                <p className='mb-0'>CISO</p>
              </div>
            </div> */}
          </OwlCarousel>
        </Container>
      </section>

      <section className='section-padd-bottom team-members '>
        <Container>
          <div className='mb-5 wow fadeInUp'>
            <h6>Stakeholders</h6>
            <h2>Meet our stakeholders</h2>
          </div>
          <OwlCarousel
            items={5}
            className='clients-slider stackholders team-members wow fadeInUp'
            nav
            dots
            margin={20}
            responsive={{
              1: { items: 2, dots: false },
              768: { items: 2 },
              1024: { items: 5 }
            }}
          >
            <div className='team-box'>
              <div className='team-member'>
                <img src={'/assets/img/team/Vineet-dhavan.png'} alt='' />
              </div>
              <div className='team-info'>
                <h6>
                  Vineet
                  <br />
                  Dhawan
                </h6>
                <p className='mb-0'>Investor</p>
              </div>
            </div>

            {/* <div className='team-box'>
              <div className='team-member'>
                <img src={'/assets/img/user.svg'} alt='' />
              </div>
              <div className='team-info'>
                <h6>
                  Satish
                  <br />
                  Shewani
                </h6>
                <p className='mb-0'>Investor</p>
              </div>
            </div> */}

            <div className='team-box'>
              <div className='team-member'>
                <img src={'/assets/img/team/guru.png'} alt='Guru Narayan' />
              </div>
              <div className='team-info'>
                <h6>
                  Guru
                  <br />
                  Narayan
                </h6>
                <p className='mb-0'>Automotive Advisor</p>
              </div>
            </div>
          </OwlCarousel>
        </Container>
      </section>

      <section className='section-padd-bottom our-sustainbility wow fadeInUp'>
        <Container>
          <h6 className='mb-5'>Our Sustainability Objectives</h6>
          <Row>
            <Col xs={6} md={2}>
              <div className='sustain-logo-box'>
                <img
                  src={'/assets/img/sustanability/un_global_comp.png'}
                  alt=''
                />
              </div>
            </Col>
            <Col xs={6} md={2}>
              <div className='sustain-logo-box'>
                <img
                  src={'/assets/img/sustanability/sustainable-development.png'}
                  alt=''
                />
              </div>
            </Col>
            <Col xs={6} md={2}>
              <div className='sustain-logo-box'>
                <img src={'/assets/img/sustanability/ghg.png'} alt='' />
              </div>
            </Col>
            <Col xs={6} md={2}>
              <div className='sustain-logo-box'>
                <img src={'/assets/img/sustanability/gri.png'} alt='' />
              </div>
            </Col>
            <Col xs={6} md={2}>
              <div className='sustain-logo-box'>
                <img src={'/assets/img/sustanability/brsr.jpg'} alt='' />
              </div>
            </Col>
            <Col xs={6} md={2}>
              <div className='sustain-logo-box'>
                <img src={'/assets/img/sustanability/sasb.jpg'} alt='' />
              </div>
            </Col>
            {/*<Col xs={6} md={3}>
                  <div className='sustain-logo-box'><img src={'/assets/img/sustanability/ipcc.png'} alt=''/></div>
                </Col>
                 <Col xs={6} md={3}>
                  <div className='sustain-logo-box'><img src={'/assets/img/Climatepledge.png'} alt=''/></div>
                </Col> */}
          </Row>
        </Container>
      </section>

      <section className='section-padd cta-section'>
        <Container>
          <div className='text-center cta-text wow fadeInUp'>
            <h2 className='mb-4 mb-lg-5'>
              Begin your decarbonization journey today
            </h2>
            <p>
              Our sustainability experts will advise you on an effective
              strategy for your business.
            </p>
            <a href='/book-a-demo' className='btn btn-primary px-4 mt-4'>
              Book A Demo
            </a>
          </div>
        </Container>
      </section>

      <Footer />
    </div>
  );
}

export default About;
