import React from 'react'
import {Container} from 'react-bootstrap';
import Header from '../../shared/header/Header';
import Footer from '../../shared/footer/Footer';

function PrivacyPolicy() {
    return (

        <div className='terms-container planeHeader'>
            <Header/>

            <section className='bookdemo-con section-padd-bottom terms-con'>
                <Container>
                    <h3 className='text-center'>Privacy Policy</h3>

                    <div className='section-padd-top'>
                        <p>This page informs you of BCV TECH’s policies regarding the collection, use and disclosure of Personal Information when you use our Products/Service (Service). BCV TECH’s privacy policy applies to website visitors, app users and customers.</p>
                        <p>We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                        <p>We use your Personal Information for providing and improving the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.</p>

                        <h5>Information Collection and Use</h5>
                        <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally, identifiable information may include, but is not limited to, your email address, other information (“Personal Information”).</p>

                        <h5>Log Data</h5>
                        <p>We collect information that your browser sends whenever you visit our Service (“Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics. In addition, we may use third party services such as Google Analytics that collect, monitor and analyse this type of information in order to increase our Service’s functionality. These third-party service providers have their own privacy policies addressing how they use such information.</p>

                        <h5>Cookies</h5>
                        <p><i>- Definitions, characteristics, and application of standards</i></p>

                        <p>Cookies are small text files that may be sent to and registered on your computer by the websites you visit, to then be re-sent to those same sites when you visit them again. It is thanks to cookies that those websites can “remember” your actions and preferences (e.g., login data, language, font size, other display settings, etc.), so that you do not need to configure them again when you visit the website at a later time, or when you change pages within a website.</p>

                        <p>Cookies are used for electronic authentication, monitoring of sessions and storage of information regarding your activities when accessing a website. Some operations within a website may not be able to be performed without the use of cookies which, in certain cases, are technically necessary for operation of the website.</p>

                        <p>When browsing a website, you may also receive cookies from websites or web servers other than the website being visited (i.e., “third-party cookies”).</p>

                        <p>According to the law that may be applicable to you, your consent may not always be necessary for cookies to be used on a website. In particular, “technical cookies” – i.e. cookies that are only used to send messages through an electronic communications network, or that are needed to provide services you request – typically do not require this consent. This includes browsing or session cookies (used to allow users to login) and function cookies (used to remember choices made by a user when accessing the website, such as language or products selected for purchase).</p>

                        <p><i>- Types of cookies used by the Website</i></p>

                        <p>The Website uses the following types of cookies:</p>

                        <ul>
                            <li>Browsing or session cookies, which are strictly necessary for the Website’s operation, and/or to allow you to use the Website’s content and Services.</li>
                            <li>Function cookies, which are used to activate specific Website functions and to configure the Website according to your choices (e.g., language), in order to improve your experience.</li>
                            <li>
                            Analytics cookies, which allow BCV TECH to understand how users make use of the Website, and to track traffic to and from the Website.
                            <p>BCV TECH also uses third-party cookies – i.e. cookies from websites / web servers other than the Website, owned by third parties. These third parties will either act as independent data controllers from BCV TECH regarding their own cookies (using the data they collect for their own purposes and under terms defined by them) or as data processors for BCV TECH (processing personal data on our behalf). For further information on how these third parties may use your information, please refer to their privacy policies:</p>
                            </li>
                            <li>
                                Google
                                <ol className='mb-3'>
                                    <li><a href='https://www.google.com/policies/privacy/partners/' target='_blank' rel="noreferrer">https://www.google.com/policies/privacy/partners/</a></li>
                                    <li><a href='https://tools.google.com/dlpage/gaoptout' target='_blank' rel="noreferrer">https://tools.google.com/dlpage/gaoptout</a></li>
                                </ol>
                                <p>BCV TECH uses Google Analytics on the Website. This is a tool developed by Google and used to collect information that permits evaluation of the use of the Website, analysis of your behavior and improvement of your experience with the Website. You can obtain more information about how to opt out of Google Analytics at:</p>
                                <a href='https://tools.google.com/dlpage/gaoptout.' target='_blank' rel="noreferrer">https://tools.google.com/dlpage/gaoptout.</a>
                            </li>
                        </ul>

                        <h5>Cookie settings:</h5>
                        <p>You can change the cookie settings for our website though our cookie banner.</p>

                        <p>You can also block or delete cookies used on the Website via your browser options. Your cookie preferences will be reset if different browsers are used to access the Website. For more information on how to set the preferences for cookies via your browser, please refer to the following instructions:</p>
                        <ul>
                            <li><a href='https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies' target='_blank' rel="noreferrer">Internet Explorer</a></li>
                            <li><a href='https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie' target='_blank' rel="noreferrer">Firefox</a></li>
                            <li><a href='https://support.google.com/chrome/answer/95647?hl=it&topic=14666&ctx=topic' target='_blank' rel="noreferrer">Chrome</a></li>
                            <li><a href='https://support.apple.com/kb/index?page=search&fac=all&q=cookies%20safari%22%3eSafari' target='_blank' rel="noreferrer">Safari</a></li>
                        </ul>

                        <p><b>CAUTION: </b>If you block or delete technical and/or function cookies used by the Website, the Website may become impossible to browse, certain services or functions of the Website may become unavailable or other malfunctions may occur. In this case, you may have to modify or manually enter some information or preferences each time you visit the Website.</p>

                        <h5>Service Providers</h5>
                        <p>We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used. These third parties have access to your Personal Information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                        <h5>Communications</h5>
                        <p>We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>

                        <h5>Security</h5>
                        <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

                        <h5>International Transfer</h5>
                        <p>Your information, including Personal Information, may be transferred to and maintained on computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those from your jurisdiction. If you are located outside India and choose to provide information to us, please note that we transfer the information, including Personal Information, to India and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>

                        <h5>Links to other Sites</h5>
                        <p>Our Products may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

                        <h5>Children's Privacy</h5>
                        <p>We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your Children have provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from a child under age 13 without verification of parental consent, we take steps to remove that information from our servers.</p>

                        <h5>Changes to this Privacy Policy</h5>
                        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                        <p>By using our Service and/or by providing your information, you consent to the collection and use of the information you disclose in accordance with this Privacy Policy, and the terms of conditions including but not limited to your consent for sharing your information as per this privacy policy.</p>

                        <h5>Contact Us</h5>
                        <p>You may contact us in case of any grievances at <a href='mailto:info@bharatcarbon.earth'>info@bharatcarbon.earth</a></p>

                    </div>

                </Container>
            </section>

            <Footer/>
        </div>
    )

}

export default PrivacyPolicy