import React, { useEffect } from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, Routes, useLocation } from "react-router-dom";
import WOW from 'wowjs';
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Bookdemo from "./pages/book-a-demo/Bookdemo";
import Products from "./pages/prodcuts/Products";
import CollectData from "./pages/prodcuts/product-details/CollectData";
import AnalyzeData from "./pages/prodcuts/product-details/AnalyzeData";
import ReportData from "./pages/prodcuts/product-details/ReportData";
import BuildStrategy from "./pages/prodcuts/product-details/BuildStrategy";
import ReduceEmissions from "./pages/prodcuts/product-details/ReduceEmissions";
import ImproveStrategy from "./pages/prodcuts/product-details/ImproveStrategy";
import TermsConditions from "./pages/terms/TermsConditions";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import Glossary from "./pages/glossary/Glossary";
import BlogList from "./pages/blog/BlogList";
import BlogDetails from "./pages/blog/BlogDetails";
import PageNotFound from "./pages/page-not-found/PageNotFound";
import OurSolutions from "./pages/our-solutions/OurSolutions";
import IOTDevices from "./pages/our-solutions/solution-details/IOTDevices";
import AIAutomation from "./pages/our-solutions/solution-details/AIAutomation";
import { Toaster } from 'react-hot-toast';

function App() {

  const location = useLocation();

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);



  return (
    <div className="bc-container">
      <Toaster toastOptions={{
        style: {
          background: '#17255499',
          color: '#fff',
          backdropFilter: 'blur(3px)'
        }
      }} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/book-a-demo" element={<Bookdemo />} />
        <Route path="/our-products" element={<Products />} />
        <Route path="/collect-data" element={<CollectData />} />
        <Route path="/analyze-data" element={<AnalyzeData />} />
        <Route path="/report-data" element={<ReportData />} />
        <Route path="/build-strategy" element={<BuildStrategy />} />
        <Route path="/reduce-emissions" element={<ReduceEmissions />} />
        <Route path="/improve-strategy" element={<ImproveStrategy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/glossary" element={<Glossary/>} />
        <Route path="/articles" element={<BlogList/>} />
        <Route path="/article-details" element={<BlogDetails/>} />

        <Route path="/our-solutions" element={<OurSolutions />} />
        <Route path="/IOT-devices" element={<IOTDevices />} />
        <Route path="/AI-automations" element={<AIAutomation/>} />

        <Route path="*" element={<PageNotFound />} />

        <Route path="/glossary" element={<Glossary />} />
        <Route path="/articles" element={<BlogList />} />
        <Route path="/article/:slug" element={<BlogDetails />} />
      </Routes>

    </div>

  );
}

export default App;
